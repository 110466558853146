import { v1 as uuidv1 } from "uuid";
import { v4 as uuidv4 } from "uuid";




export const BLOCK_TYPES = {
    NONE: { id: 'NONE', icon: 'Type' },
    TOP: { id: 'TOP', icon: 'ImagePlus' },
    LEFT: { id: 'LEFT', icon: 'Layout' },
    RIGHT: { id: 'RIGHT', icon: 'Layout' },
    BACKGROUND: { id: 'BACKGROUND', icon: 'Image' }
};

export function webBuilderReducer(state, action) {
    switch (action.type) {
        case 'CHANGE_GENERATION_STATUS':
            return {
                ...state,
                isGenerating: action.payload.status
            }
        case 'ADD_BLOCK':
            const newBlock = {
                id: `${Math.floor(10000000 + Math.random() * 90000000).toString()}${Date.now()}`,
                type: BLOCK_TYPES.NONE.id,
                content: [{
                    "id": `${Math.floor(10000000 + Math.random() * 90000000).toString()}${Date.now()}`,
                    "type": "TEXT",
                    "data": {
                        "text": "<h3>YOUR CONTENT HERE</h3>",
                    }
                }],
                imageUrl: '/api/placeholder/800/400',
                color: '#ffffff'
            };

            if (typeof action.payload.index === 'number') {
                const newBlocks = [...state.blocks];
                newBlocks.splice(action.payload.index + 1, 0, newBlock);
                return { ...state, blocks: newBlocks };
            }
            return { ...state, blocks: [...state.blocks, newBlock] };

        case 'ADD_GENERATED_BLOCK':
            return {
                ...state,
                blocks: [...state.blocks, action.payload]
            }

        case 'REMOVE_BLOCK':
            return {
                ...state,
                blocks: state.blocks.filter(block => block.id !== action.payload.id),
                activeMenu: { type: null, blockId: null }
            };

        case 'DUPLICATE_BLOCK':
            const blockToDuplicate = state.blocks[action.payload.index];
            const duplicatedBlock = { ...blockToDuplicate, id: `${Math.floor(10000000 + Math.random() * 90000000).toString()}${Date.now()}` };
            const blocksWithDuplicate = [...state.blocks];
            blocksWithDuplicate.splice(action.payload.index + 1, 0, duplicatedBlock);
            return {
                ...state,
                blocks: blocksWithDuplicate,
                activeMenu: { type: null, blockId: null }
            };

        case 'CHANGE_BLOCK_TYPE':
            return {
                ...state,
                blocks: state.blocks.map(block =>
                    block.id === action.payload.id
                        ? { ...block, type: action.payload.blockType }
                        : block
                ),
                activeMenu: { type: null, blockId: null }
            };

        case 'UPDATE_BLOCK_COLOR':
            return {
                ...state,
                blocks: state.blocks.map(block =>
                    block.id === action.payload.id
                        ? { ...block, color: action.payload.color }
                        : block
                )
            };

        case 'UPDATE_BLOCK_TEXT':

            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.itemId
                                ? { ...item, data: { ...item.data, text: action.payload.newText } }
                                : item
                        );
                        return { ...block, content: updatedContent };
                    }
                    return block;
                })
            };

        case 'TOGGLE_MENU':
            const { menuType, blockId } = action.payload;
            console.log(menuType, blockId);
            return {
                ...state,
                activeMenu: state.activeMenu.type === menuType && state.activeMenu.blockId === blockId
                    ? { type: null, blockId: null }
                    : { type: menuType, blockId }
            };

        case 'CLOSE_MENU':
            return {
                ...state,
                activeMenu: { type: null, blockId: null }
            };

        case 'UPDATE_BLOCK_ID':
            return {
                ...state,
                activeBlock: {
                    ...state.activeBlock,
                    blockId: action.payload.blockId
                }

            };

        case 'UPDATE_CONTENT_ID':
            return {
                ...state,
                activeBlock: {
                    ...state.activeBlock,
                    contentId: action.payload.contentId
                }
            }

        case "REMOVE_ACTIVE_BLOCK":
            return {
                ...state,
                activeBlock: {
                    blockId: null,
                    contentId: null
                }
            }

        case "ADD_CONTENT":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const { newcontent, index } = action.payload;

                        console.log("this is incoming index", index);

                        let updatedContent;
                        if (index === null || index === undefined) {
                            updatedContent = [...block.content, newcontent];
                        } else {

                            updatedContent = [...block.content];
                            updatedContent.splice(index + 1, 0, newcontent);
                        }

                        return { ...block, content: updatedContent };
                    }
                    return block;
                })
            };

        case "ADD_GRID_IMAGE":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {

                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    images: [...item.images, action.payload.image]
                                }
                                : item
                        );
                        console.log("this is block", updatedContent);
                        return { ...block, content: updatedContent };

                    }
                })
            }


        case "UPDATE_GRID_IMAGE":

            console.log("this is action payload", action.payload);
            console.log("this is state", state);
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    images: item.images.map(image => image.id === action.payload.image.id ? action.payload.image : image)
                                }
                                : item
                        );
                        console.log("this is block", updatedContent);

                        return { ...block, content: updatedContent };

                    }
                    return block;
                })
            };


        case "ADD_BUTTON_TO_BUTTON_LIST":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    buttonsList: [...item.buttonsList, action.payload.button]
                                }
                                : item
                        );
                        console.log("this is block", updatedContent);
                        return { ...block, content: updatedContent };

                    }
                })
            }

        case "UPDATE_BUTTON_IN_BUTTON_LIST":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    buttonsList: item.buttonsList.map(button => button.id === action.payload.button.id ? action.payload.button : button)
                                }
                                : item
                        );
                        console.log("this is block", updatedContent);
                        return { ...block, content: updatedContent };

                    }
                })
            }

        case "ADD_TESTIMONIAL":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    testimonials: [...item.testimonials, action.payload.testimonial]
                                }
                                : item
                        );
                        console.log("this is block", updatedContent);
                        return { ...block, content: updatedContent };

                    }
                })
            }

        case "UPDATE_TESTIMONIAL":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    testimonials: item.testimonials.map(testimonial => testimonial.id === action.payload.testimonial.id ? action.payload.testimonial : testimonial)
                                }
                                : item
                        );
                        console.log("this is block", updatedContent);
                        return { ...block, content: updatedContent };

                    }
                })
            }

        case "UPDATE_SINGLE_IMAGE":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    image: action.payload.image,
                                    dimensions: action.payload.dimensions
                                }
                                : item
                        );
                        console.log("this is image block", updatedContent);
                        return { ...block, content: updatedContent };
                    }
                })
            }

        case "UPDATE_YOUTUBE_VIDEO":
            return {
                ...state,
                blocks: state.blocks.map(block => {
                    if (block.id === action.payload.blockId) {
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    video: action.payload.video,
                                    dimensions: action.payload.dimensions
                                }
                                : item
                        );
                        console.log("this is image block", updatedContent);
                        return { ...block, content: updatedContent };
                    }
                })
            }


        default:
            return state;
    }
}

