import React from 'react'
import EventsNearbyMapNav from '../../Components/EventsNearbyMapNav/EventsNearbyMapNav'
import GoogleMap from "./GoogleMap"
import CardLoading from "./CardLoading"
import { eventsDataContext } from '../../contexts/context/eventsDataContext'
import { useHistory } from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URL;

function EventsNearbyMap() {

    const history = useHistory();


    const { eventsData } = React.useContext(eventsDataContext);


    return ( 
        <div className="events-nearby">
  <div className="main-sec d-flex">
    {/* Ensure `main-sec` takes full height */}
    <div
      className="events-panel custom-scroll"
      style={{ overflowY: 'auto', height: '100%' }} // Added height: 100% and changed overflowY to auto
    >
      <div className="d-flex flex-column" style={{ height: '100%' }}>
        <div className="flex-grow-1">
          {eventsData.isFetching ? (
            <CardLoading />
          ) : eventsData.events.length > 0 ? (
            eventsData.events.map((event, index) => {
              const pageJson = JSON.parse(event.page_json);
              const eventProperties = pageJson.find(item => item.type === "EVENT").properties;
              const eventHeader = pageJson.find(item => item.type === "HEADER").properties;

              let hostName1 = Array.isArray(eventProperties.hostName)
                ? eventProperties.hostName.join(", ")
                : eventProperties.hostName;

              return (
                <div
                  key={event._id}
                  className="section-body d-flex align-items-start"
                  onClick={() => history.push(`/${event.backhalf}`)}
                >
                  <div className="left-body">
                    <div className="img-wrapper">
                      {Array.isArray(eventHeader.backgroundImage)
                        ? eventHeader.backgroundImage.map((image, index) => (
                            <img
                              key={index}
                              src={`${API_URL}/flowpage/${image}`}
                              alt={`host-${index}`}
                              className="host-img"
                            />
                          ))
                        : (
                          <img
                            src={`${API_URL}/flowpage/${eventHeader.backgroundImage}`}
                            alt=""
                            className="host-img"
                          />
                        )}
                    </div>
                  </div>
                  <div className="right-body ms-2">
                    <div className="head">{eventProperties.eventTitle}</div>
                    <div className="d-flex flex-column p-0">
                      <div className="d-flex align-items-center" style={{ marginTop: '5px' }}>
                        <div className="avatars d-flex align-items-center">
                          {/* Example avatars */}
                          <img src="..." alt="" />
                          <img src="..." alt="" />
                        </div>
                        <div className="d-flex text-ellipsis light-txt ms-2">
                          By {hostName1}
                        </div>
                      </div>
                      <div className="light-txt d-flex align-items-center mt-1">
                        {eventProperties.startDate} - {eventProperties.location}
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-1">
                        <div className="badge rounded-pill">Sold Out</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-events">
              Sorry, no events nearby
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="map-section">
      <GoogleMap />
    </div>
  </div>
</div>

    )
}

export default EventsNearbyMap