import axios from "axios";
import { useEffect, useState } from "react";

const Consumer_Key = process.env.REACT_APP_LINKEDIN_CONSUMER_KEY;
const Consumer_Secret = process.env.REACT_APP_LINKEDIN_CONSUMER_SECRET;
const Tebor_API_KEY = process.env.REACT_APP_LINKEDIN_TENOR_API_KEY;
const Ghipy_Api_Key = process.env.REACT_APP_LINKEDIN_GHIPY_API_KEY;
const UNSPLASH_KEY = process.env.REACT_APP_LINKEDIN_UNSPLASH_KEY;
const PIXELS_KEY = process.env.REACT_APP_LINKEDIN_PIXELS_KEY ?? "wcIA3TC2KXoiScSd0A1YsdfUwJJLhwryrCL1uee04wn6upWlpJ7plEAq" // process.env.REACT_APP_LINKEDIN_PIXELS_KEY;

const useFetchImages = (searchTerm, selectedAPI, tabIndex, everyEventCallaApi, setEveryEventCallaApi) => {
    const [loading, setLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [gifUrls, setGifUrls] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [dataAvailable, setDataAvailable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const apiUrl = getApiUrl(selectedAPI, searchTerm, tabIndex);
                const headers = {
                    Authorization: await getAuthorizationHeader(selectedAPI),
                };
                const params = selectedAPI === 'shutterstock' ? { query: searchTerm, sort: 'popular', view: 'minimal' } : {};
                const { data } = await axios.get(apiUrl, { params, headers });

                if (data) {
                    handleApiResponse(selectedAPI, data, tabIndex);
                    setEveryEventCallaApi(true);
                    setDataAvailable(true);
                } else {
                    setDataAvailable(false);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
                setDataAvailable(false);
            }
        };

        if (searchTerm) {
            fetchData();
       }
    }, [searchTerm, selectedAPI, tabIndex, everyEventCallaApi]);

    const getApiUrl = (api, query, tab) => {
        switch (api) {
            case 'unsplash':
                return `https://api.unsplash.com/search/photos?query=${query}`;
            case 'pexels':
                return tab === 'video'
                    ? `https://api.pexels.com/videos/search?query=${query}`
                    : `https://api.pexels.com/v1/search?query=${query}`;
            case 'tenor':
                return `https://tenor.googleapis.com/v2/search?key=${Tebor_API_KEY}&q=${encodeURIComponent(query)}`;
            case 'giphy':
                return `https://api.giphy.com/v1/gifs/search?api_key=${Ghipy_Api_Key}&q=${encodeURIComponent(query)}`;
            case 'storyblocks':
                return `https://api.storyblocks.com/v1/search?query=${query}`;
            case 'shutterstock':
                return `https://api.shutterstock.com/v2/images/search`;
            default:
                return `https://api.unsplash.com/search/photos?query=${query}`;
        }
    };

    const getAuthorizationHeader = async (api) => {
        switch (api) {
            case 'unsplash':
                return `Client-ID ${UNSPLASH_KEY}`;
            case 'pexels':
                return `${PIXELS_KEY}`;
            case 'storyblocks':
                return 'Bearer test_102e5a978715f8613a027e8addd2962f5ea5637794d5fcad47562e9a18a';
            case 'shutterstock': {
                const response = await axios.post(
                    'https://api.shutterstock.com/v2/oauth/access_token',
                    {
                        client_id: Consumer_Key,
                        client_secret: Consumer_Secret,
                        grant_type: 'client_credentials'
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );

                return `Bearer ${response.data.access_token}`;
            }
            default:
                return '';
        }
    };

    const handleApiResponse = (api, data, tab) => {
        switch (tab) {
            case 'Gif':
                handleGifResponse(api, data);
                break;
            case 'video':
                handleVideoResponse(api, data);
                break;
            default:
                handleImageResponse(api, data);
        }
    };

    const handleGifResponse = (api, data) => {
        let fetchedGifUrls = [];
        switch (api) {
            case 'tenor':
                fetchedGifUrls = data.results.map(gif => gif.media_formats.gif.url);
                break;
            case 'giphy':
                fetchedGifUrls = data.data.map(gif => gif.images.original.url);
                break;
        }
        setGifUrls(fetchedGifUrls);
        setVideoUrls([]);
        setImageUrls([]);
    };

    const handleVideoResponse = (api, data) => {
        let fetchedVideoUrls = [];
        switch (api) {
            case 'pexels':
                fetchedVideoUrls = data.videos.map(video => video.video_files[0].link);
                break;
            case 'shutterstock':
                fetchedVideoUrls = data.data.map(video => video.assets.preview_mp4.url);
                break;
            case 'tenor':
                fetchedVideoUrls = data.results.map(gif => gif.media_formats.mp4.url);
                break;
        }
        setVideoUrls(fetchedVideoUrls);
        setGifUrls([]);
        setImageUrls([]);
    };

    const handleImageResponse = (api, data) => {
        let fetchedImageUrls = [];
        switch (api) {
            case 'pexels':
                fetchedImageUrls = data.photos.map(photo => photo.src.medium);
                break;
            case 'unsplash':
                fetchedImageUrls = data.results.map(photo => photo.urls.regular);
                break;
            case 'shutterstock':
                fetchedImageUrls = data.data.map(photo => photo.assets.preview.url);
                break;
            case 'tenor':
                fetchedImageUrls = data.results.map(gif => gif.media_formats.gifpreview.url);
                break;
        }
        setImageUrls(fetchedImageUrls);
        setGifUrls([]);
        setVideoUrls([]);
    };

    const isUnsupportedCombination = (api, tab) => {
        return (
            (api === 'unsplash' && (tab === 'video' || tab === "Gif")) || // Unsplash does not provide videos or GIFs
            (api === 'giphy' && (tab === 'video' || tab === "image")) || // Giphy does not provide videos or images
            (api === 'pexels' && tab === 'Gif') || // Pexels does not provide GIFs
            (api === 'shutterstock' && tab === 'Gif') // Shutterstock does not provide GIFs
        );
    };

    return { loading, imageUrls, gifUrls, videoUrls, isUnsupportedCombination, dataAvailable };
};

export default useFetchImages;