import React from 'react'
import { API_URL } from "../../BackendApi/Api/config";
import '../../Events/DiscoverEvents/DiscoverEvents.css'
import LocationDisplay from '../locationsType/LocationType';
const TimeLine = ({ eventData, type }) => {
    const handleEventClick = (event) => {
        window.open(`${API_URL}${event}`, "_blank");
    }
    return (
        <div className="timeline">
            <div className="timeline-section row">
                {Array.isArray(eventData) && eventData.length > 0 && (
                    (() => {
                        const groupedEvents = eventData.reduce((acc, eventItem) => {
                            const eventPro = type === "category" ? eventItem : eventItem?.event;

                            const eventDate = new Date(eventPro?.startDate).toDateString(); // Group by date
                            acc[eventDate] = acc[eventDate] || [];
                            acc[eventDate].push(eventItem);
                            return acc;
                        }, {});

                        return Object.entries(groupedEvents).map(([date, events], index) => {
                            // Sort events by startDate for each date group
                            const sortedEvents = events.sort((a, b) => {
                                const startDateA = new Date((type === "category" ? a : a?.event)?.startDate);
                                const startDateB = new Date((type === "category" ? b : b?.event)?.startDate);
                                return startDateA - startDateB;
                            });

                            return (

                                <div key={index}>

                                    <div className="timeline-title-sec col-md-11">
                                        <div className="line col-md-1"></div>

                                        <div className="contain d-flex align-items-center" style={{ marginTop: 20 }}>
                                            <div className="mid-dot me-2" style={{ background: "white" }}></div>
                                            
                                            <div className="timeline-title-sticky d-flex align-items-center">
                                                <h5 className="timeline-title me-2">{date}</h5>
                                            </div>
                                        </div>

                                        {sortedEvents.map((eventItem, idx) => {
                                            const eventPro = type === "category" ? eventItem : eventItem?.event;
                                            let backhalf = type === "category" ? eventItem?.backhalf : eventItem?.event?.backHalf;
                                            const eventTime = new Date(eventPro?.startDate).toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            });
                                            const rightsideImg =
                                                type === "category"
                                                    ? eventPro?.eventBlock2?.backgroundImage
                                                    : eventPro?.eventBlock2?.properties?.backgroundImage;


                                            let hostImage1 = "https://cdn.lu.ma/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=16,height=16/avatars-default/avatar_18.png";

                                            let hostName1
                                            if (Array.isArray(eventPro?.hostName)) {
                                                hostName1 = eventPro?.hostName?.slice(0, 2).map((i) => i).join(", ");
                                            } else {
                                                hostName1 = eventPro?.hostName;
                                            }
                                            let hostImages = eventPro?.hostedImage || hostImage1;
                                            return (
                                                <div key={idx} className="timeline-card d-flex align-items-center justify-content-between ms-3 mt-3" onClick={() => handleEventClick(backhalf)}>
                                                    <div className="wrapper">
                                                        <h6 className="d-flex light-txt">{eventTime}</h6>
                                                        <h4 className="d-flex mt-1">
                                                            {eventPro?.eventTitle || "Unknown Event"}
                                                        </h4>
                                                        <div className="d-flex light-txt align-items-center">
                                                            <div className="followers-icon d-flex me-2">
                                                                <div className="total-people-inner pb-2">
                                                                    <div className="total-people-inner pb-2">
                                                                        {Array.isArray(hostImages) ? (
                                                                            <>
                                                                                {hostImages?.slice(0, 2).map((image, index) => (
                                                                                    <img
                                                                                        key={index}
                                                                                        src={API_URL + "profile/" + image}
                                                                                        alt={`host-${index}`}
                                                                                        style={{
                                                                                            marginTop: '4px',
                                                                                            width: '24px',
                                                                                            height: '24px',
                                                                                            borderRadius: '50%',
                                                                                            marginRight: '8px'
                                                                                        }}
                                                                                    />
                                                                                ))}

                                                                            </>
                                                                        ) : (
                                                                            <img
                                                                                src={API_URL + "profile/" + hostImages}
                                                                                alt="host"
                                                                                style={{
                                                                                    marginTop: '4px',
                                                                                    width: '24px',
                                                                                    height: '24px',
                                                                                    borderRadius: '50%',
                                                                                    marginRight: '8px'
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="light-txt ">By {hostName1}  {Array.isArray(eventPro?.hostName) && eventPro?.hostName.length > 2 && (
                                                                <span> and {eventPro?.hostName.length - 2} others</span>
                                                            )}</span>
                                                        </div>

                                                        <LocationDisplay eventData={eventPro} />
                                                    </div>
                                                    <div className="wrapper-img">
                                                        <img
                                                            src={`${API_URL}flowpage/${rightsideImg}` || "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=180,height=180/event-covers/kw/5d3f68fb-d18b-4d88-aaac-2e2954988f97"}
                                                            alt="Event"
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )
                        });
                    })()
                )}


            </div>

        </div>
    )
}

export default TimeLine
