import React from 'react'
import TextEditor from './components/texteditor/TextEditor'
import { WebBuilderContext } from '../../contexts/context/webBuilderContext'
import HorizontalButtonList from './components/buttons/ButtonsList'
import ImageGrid from './components/images/images-grid/ImageGrid'
import TestimonialList from './components/testimonials/Testimonials'
import SingleImage from './components/images/single-image/SingleImage'
import YoutubeVideo from './components/videos/YoutubeVideo'
import Columns from './components/columns/Columns'
import TextCards from "./components/text-cards/TextCards"
import NumberedTextCards from "./components/text-cards/NumberedTextCards"
import NumberedStepCards from "./components/text-cards/NumberedStepCards"
import PentagonStepCard from "./components/text-cards/PentagonStepCard"

const ComponentsList = ({ blockId, content }) => {

    const { webBuilderDispatch } = React.useContext(WebBuilderContext)

    return (
        <>

            {

                content.map((item, index) => {

                    switch (item.type) {
                        case "TEXT":
                            return (

                                <TextEditor
                                    blockId={blockId}
                                    content={item}
                                    key={item.id}
                                />

                            )

                        case "BUTTONS":
                            return (
                                <HorizontalButtonList
                                    blockId={blockId}
                                    content={item}
                                    key={item.id}
                                />
                            )

                        case "IMAGE_GRID":
                            return (
                                <ImageGrid
                                    blockId={blockId}
                                    content={item}
                                    key={item.id}
                                />
                            )

                        case "TESTIMONIALS":
                            return (
                                <TestimonialList
                                    blockId={blockId}
                                    content={item}
                                    key={item.id}
                                />
                            )

                        case "SINGLE_IMAGE":
                            return (
                                <SingleImage
                                    blockId={blockId}
                                    content={item}
                                    key={item.id}
                                />
                            )

                        case "YOUTUBE_VIDEO":
                            return (
                                <YoutubeVideo
                                    blockId={blockId}
                                    content={item}
                                    key={item.id}
                                />
                            )

                            case "COLUMNS":
                            return (
                                <Columns 
                                    blockId={blockId}
                                    content={item}
                                    key={item.id}
                                />
                            )
                        default:
                            return <></>
                    }

                })


            }

            
            {/* <TextCards /> */}
            {/* <NumberedTextCards />
            <NumberedStepCards />
            <PentagonStepCard /> */}

        </>
    )
}

export default ComponentsList