import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Star, StarHalf, Edit } from 'lucide-react';
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';
import { v4 as uuidv4 } from "uuid";

const API_URL = process.env.REACT_APP_API_URL;

const TestimonialList = ({ blockId, content }) => {
    const { webBuilderDispatch } = useContext(WebBuilderContext);
    const [testimonials, setTestimonials] = useState([]);
    const [activeTestimonial, setActiveTestimonial] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Form states
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [rating, setRating] = useState(5);

    const handleTestimonialClick = (testimonial) => {
        setActiveTestimonial(testimonial);
        setTitle(testimonial.title || "");
        setDescription(testimonial.description || "");
        setRating(testimonial.rating || 5);
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setUploadProgress(0);

        const formData = new FormData();
        formData.append('image', file);

        try {
            const interval = setInterval(() => {
                setUploadProgress(prev => {
                    if (prev >= 90) {
                        clearInterval(interval);
                        return 90;
                    }
                    return prev + 10;
                });
            }, 300);

            const response = await fetch(`${API_URL}/web-builder/upload-image`, {
                method: 'POST',
                body: formData
            });

            clearInterval(interval);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.status) {
                if (activeTestimonial) {
                    const updatedTestimonial = {
                        ...activeTestimonial,
                        image: data.data.filename,
                    };
                    setActiveTestimonial(updatedTestimonial);
                }
            } else {
                throw new Error(data.message);
            }

            setUploadProgress(100);
            setTimeout(() => {
                setIsUploading(false);
                setUploadProgress(0);
            }, 500);

        } catch (error) {
            console.error('Upload failed:', error);
            setIsUploading(false);
            setUploadProgress(0);
            alert('Failed to upload image. Please try again.');
            e.target.value = '';
        }
    };

    const handleSave = () => {
        if (activeTestimonial) {
            const updatedTestimonial = {
                ...activeTestimonial,
                title,
                description,
                rating,
            };

            setTestimonials((prevTestimonials) =>
                prevTestimonials.map((item) =>
                    item.id === activeTestimonial.id ? updatedTestimonial : item
                )
            );

            webBuilderDispatch({
                type: 'UPDATE_TESTIMONIAL',
                payload: {
                    blockId,
                    contentId: content.id,
                    testimonial: updatedTestimonial
                }
            });
        }
        closeEditor();
    };

    const closeEditor = () => {
        setActiveTestimonial(null);
        setTitle("");
        setDescription("");
        setRating(5);
    };

    const addNewTestimonial = () => {
        const newTestimonial = {
            id: `${uuidv4()}-${Math.floor(10000000 + Math.random() * 90000000).toString()}-${Date.now()}`,
            image: "https://via.placeholder.com/150",
            title: "New Testimonial",
            description: "Add your testimonial description here",
            rating: 5
        };
        setTestimonials([...testimonials, newTestimonial]);
        webBuilderDispatch({
            type: 'ADD_TESTIMONIAL',
            payload: { blockId, contentId: content.id, testimonial: newTestimonial }
        });
    };

    useEffect(() => {
        setTestimonials(content.testimonials || []);
    }, []);

    return (
        <div
            className="container py-4"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                webBuilderDispatch({
                    type: 'UPDATE_CONTENT_ID',
                    payload: { contentId: content.id }
                });
            }}
        >
            <div className="row g-4">
                {testimonials.map((testimonial) => (
                    <div key={testimonial.id} className="col-md-4">
                        <div 
                            className="card h-100 shadow-sm"
                            onClick={() => handleTestimonialClick(testimonial)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="position-relative">
                                <img
                                    src={testimonial.image.startsWith("http") ? testimonial.image : `${API_URL}/web-builder/${testimonial.image}`}
                                    alt={testimonial.title}
                                    className="card-img-top"
                                    style={{ height: '200px', objectFit: 'cover' }}
                                />
                                <div 
                                    className="position-absolute top-0 end-0 m-2"
                                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '50%', padding: '8px' }}
                                >
                                    <Edit size={16} />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex mb-2">
                                    {[...Array(5)].map((_, index) => (
                                        <Star
                                            key={index}
                                            size={16}
                                            fill={index < testimonial.rating ? "#ffc107" : "none"}
                                            stroke={index < testimonial.rating ? "#ffc107" : "#6c757d"}
                                        />
                                    ))}
                                </div>
                                <h5 className="card-title">{testimonial.title}</h5>
                                <p className="card-text">{testimonial.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {isHovered && (
                <div className="d-flex justify-content-center mt-4">
                    <button 
                        className="btn btn-primary"
                        onClick={addNewTestimonial}
                    >
                        Add New Testimonial
                    </button>
                </div>
            )}

            {activeTestimonial && ReactDOM.createPortal(
                <div
                    className="modal show fade d-block"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Testimonial</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={closeEditor}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3 text-center">
                                    <img
                                        src={activeTestimonial.image.startsWith("http") ? activeTestimonial.image : `${API_URL}/web-builder/${activeTestimonial.image}`}
                                        alt="Preview"
                                        className="img-fluid mb-2 rounded"
                                        style={{ maxHeight: '200px', objectFit: 'cover' }}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Upload Image</label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileUpload}
                                        className="form-control"
                                        disabled={isUploading}
                                    />
                                    {isUploading && (
                                        <div className="mt-2">
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: `${uploadProgress}%` }}
                                                    aria-valuenow={uploadProgress}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                >
                                                    {uploadProgress}%
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter testimonial title"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="form-control"
                                        rows="3"
                                        placeholder="Enter testimonial description"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Rating</label>
                                    <div className="d-flex gap-2">
                                        {[1, 2, 3, 4, 5].map((value) => (
                                            <Star
                                                key={value}
                                                size={24}
                                                fill={value <= rating ? "#ffc107" : "none"}
                                                stroke={value <= rating ? "#ffc107" : "#6c757d"}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setRating(value)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                    disabled={isUploading}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default TestimonialList;