import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Plus } from 'lucide-react';
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';
import { v4 as uuidv4 } from "uuid";

const HorizontalButtonList = ({ blockId, content }) => {
    const { webBuilderDispatch } = useContext(WebBuilderContext);
    const [buttons, setButtons] = useState([]);
    const [activeButton, setActiveButton] = useState(null);
    const [buttonText, setButtonText] = useState("");
    const [buttonVariant, setButtonVariant] = useState("primary");
    const [buttonUrl, setButtonUrl] = useState("");
    const [isHovered, setIsHovered] = useState(false);
    
    const variants = ['primary', 'secondary', 'success', 'danger', 'warning', 'info'];

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setButtonText(button.text || "");
        setButtonVariant(button.variant || "primary");
        setButtonUrl(button.url || "");
    };

    const handleSave = () => {
        if (activeButton) {
            const updatedButton = {
                ...activeButton,
                text: buttonText,
                variant: buttonVariant,
                url: buttonUrl
            };
            
            setButtons((prevButtons) =>
                prevButtons.map((btn) =>
                    btn.id === activeButton.id ? updatedButton : btn
                )
            );
            
            webBuilderDispatch({ 
                type: 'UPDATE_BUTTON_IN_BUTTON_LIST', 
                payload: { 
                    blockId, 
                    contentId: content.id, 
                    button: updatedButton 
                } 
            });
        }
        closeEditor();
    };

    const closeEditor = () => {
        setActiveButton(null);
        setButtonText("");
        setButtonVariant("primary");
        setButtonUrl("");
    };

    const addNewButton = () => {
        const newButton = {
            id: `${uuidv4()}-${Math.floor(10000000 + Math.random() * 90000000).toString()}-${Date.now()}`,
            text: `Button ${buttons.length + 1}`,
            variant: 'primary',
            url: '',
        };
        setButtons([...buttons, newButton]);
        webBuilderDispatch({ 
            type: 'ADD_BUTTON_TO_BUTTON_LIST', 
            payload: { blockId, contentId: content.id, button: newButton } 
        });
    };

    useEffect(() => {
        setButtons(content.buttonsList || []);
    }, []);

    return (
        <div
            className="d-flex align-items-center gap-3 p-3 overflow-auto flex-wrap"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                webBuilderDispatch({ 
                    type: 'UPDATE_CONTENT_ID', 
                    payload: { contentId: content.id } 
                });
            }}
        >
            {buttons.map((button) => (
                <div key={button.id} className="position-relative">
                    <button
                        className={`btn btn-${button.variant}`}
                        style={{
                            borderRadius: '6px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s',
                        }}
                        onClick={() => handleButtonClick(button)}
                    >
                        {button.text}
                    </button>
                </div>
            ))}

            {isHovered && (
                <button
                    onClick={addNewButton}
                    className="btn btn-primary d-flex align-items-center gap-1"
                    style={{
                        borderRadius: '6px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#007bff',
                    }}
                >
                    <Plus size={16} />
                    Add New Button
                </button>
            )}

            {activeButton && ReactDOM.createPortal(
                <div
                    className="modal show fade d-block"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Button</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={closeEditor}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Button Text</label>
                                    <input
                                        type="text"
                                        value={buttonText}
                                        onChange={(e) => setButtonText(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter button text"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Button URL</label>
                                    <input
                                        type="url"
                                        value={buttonUrl}
                                        onChange={(e) => setButtonUrl(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter button URL"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Button Style</label>
                                    <div className="d-flex gap-2 flex-wrap">
                                        {variants.map((variant) => (
                                            <button
                                                key={variant}
                                                type="button"
                                                onClick={() => setButtonVariant(variant)}
                                                className={`btn btn-${variant} ${
                                                    buttonVariant === variant ? 'border border-dark' : ''
                                                }`}
                                                style={{
                                                    minWidth: '60px',
                                                    borderRadius: '6px',
                                                    transition: 'transform 0.2s',
                                                    transform: buttonVariant === variant ? 'scale(1.1)' : 'scale(1)',
                                                }}
                                            >
                                                {variant}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default HorizontalButtonList;