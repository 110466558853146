import React from 'react';

const LoadingOverlay = () => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem'
  };

  return (
    <div style={overlayStyle}>
      <div className="card p-4 text-center">
        <div className="d-flex justify-content-center mb-3">
          <div 
            className="spinner-border text-primary" 
            style={spinnerStyle} 
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <p className="mb-0 fw-medium">Please wait, generating content...</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;