import React, { useState, useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { WebBuilderContext } from '../../../../../contexts/context/webBuilderContext';
import { FiMoreVertical, FiPlusCircle } from "react-icons/fi";

const API_URL = process.env.REACT_APP_API_URL;

const SingleImageEditor = ({ blockId, content }) => {
    const { webBuilderDispatch } = useContext(WebBuilderContext);
    const [image, setImage] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [altText, setAltText] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [dimensions, setDimensions] = useState({ width: 200, height: 150 });
    const [isResizing, setIsResizing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const imageRef = useRef(null);
    const startResizePos = useRef({ x: 0, y: 0 });
    const startDimensions = useRef({ width: 0, height: 0 });

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setUploadProgress(0);

        const formData = new FormData();
        formData.append('image', file);

        try {
            const interval = setInterval(() => {
                setUploadProgress(prev => {
                    if (prev >= 90) {
                        clearInterval(interval);
                        return 90;
                    }
                    return prev + 10;
                });
            }, 300);

            const response = await fetch(`${API_URL}/web-builder/upload-image`, {
                method: 'POST',
                body: formData
            });

            clearInterval(interval);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.status) {
                setImage({ src: data.data.filename, alt: altText });
            } else {
                throw new Error(data.message);
            }

            setUploadProgress(100);
            setTimeout(() => {
                setIsUploading(false);
                setUploadProgress(0);
            }, 500);

        } catch (error) {
            console.error('Upload failed:', error);
            setIsUploading(false);
            setUploadProgress(0);
            alert('Failed to upload image. Please try again.');
            e.target.value = '';
        }
    };

    const handleStartResize = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsResizing(true);
        startResizePos.current = { x: e.clientX, y: e.clientY };
        startDimensions.current = { ...dimensions };
    };

    const handleResize = (e) => {
        if (!isResizing) return;

        const deltaX = e.clientX - startResizePos.current.x;
        const deltaY = e.clientY - startResizePos.current.y;

        setDimensions({
            width: Math.max(100, startDimensions.current.width + deltaX),
            height: Math.max(100, startDimensions.current.height + deltaY)
        });
    };

    const handleStopResize = () => {
        if (isResizing) {
            handleSave();
            setIsResizing(false);
        }
    };

    useEffect(() => {
        if (content.image) {
            setImage(content.image);
            setAltText(content.image.alt || "");
            setDimensions(content.dimensions || { width: 200, height: 150 });
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousemove', handleResize);
        document.addEventListener('mouseup', handleStopResize);

        return () => {
            document.removeEventListener('mousemove', handleResize);
            document.removeEventListener('mouseup', handleStopResize);
        };
    }, [isResizing]);

    const handleSave = () => {
        if (image) {
            webBuilderDispatch({ 
                type: 'UPDATE_SINGLE_IMAGE', 
                payload: { 
                    blockId, 
                    contentId: content.id, 
                    image: { ...image, alt: altText }, 
                    dimensions 
                } 
            });
        }
        setIsEditing(false);
    };

    const closeEditor = () => {
        setIsEditing(false);
    };

    return (
        <div 
            className="container position-relative py-4"
            onMouseEnter={(e) => {setIsHovered(true);
                e.currentTarget.querySelector(".container-hover-border").classList.remove("d-none");
                e.currentTarget.querySelector(".container-three-dot-menu").classList.remove("d-none");
                e.currentTarget.querySelector(".card-plus-menu-icon").classList.remove("d-none");
            }
            }
            onMouseLeave={(e) => {setIsHovered(false);
                e.currentTarget.querySelector(".container-hover-border").classList.add("d-none");
                e.currentTarget.querySelector(".container-three-dot-menu").classList.add("d-none");
                e.currentTarget.querySelector(".card-plus-menu-icon").classList.add("d-none");
            }}
            onClick={() => {
                webBuilderDispatch({ 
                    type: 'UPDATE_CONTENT_ID', 
                    payload: { contentId: content.id } 
                });
            }}
        >
            {/* Hover Border for Container */}
                  <div
                    className="container-hover-border d-none position-absolute w-100 h-100 border border-2"
                    style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
                  ></div>
            
                  {/* 3-Dot Menu for Container */}
                  <div
                    className="container-three-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
                    style={{ zIndex: 10, top: "0px", left: "-36px" }}
                  >
                    <button
                      className="btn btn-link text-secondary"
                      onClick={() => console.log(`3-dot menu clicked for the container`)}
                    >
                      <FiMoreVertical size={20} />
                    </button>
                  </div>
                  <div
                    className="card-plus-menu-icon d-none position-absolute bg-white shadow-sm rounded"
                    style={{
                      zIndex: 15,
                      right: "0px",
                      top: "0px",
                      bottom: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isHovered && (
                      <button className="btn btn-link text-secondary" >
                        <FiPlusCircle size={20} />
                      </button>
                    )}
                  </div>
            <div className="text-center mb-4 position-relative">
                {image && (
                    <div 
                        className="d-inline-block position-relative"
                        style={{ 
                            width: dimensions.width, 
                            height: dimensions.height,
                        }}
                    >
                        <img
                            ref={imageRef}
                            src={image.src.startsWith("http") || image.src.startsWith("https") 
                                ? image.src 
                                : `${API_URL}/web-builder/${image.src}`}
                            alt={image.alt || "Image"}
                            style={{ 
                                width: "100%", 
                                height: "100%", 
                                objectFit: "contain", 
                                borderRadius: "8px",
                                cursor: isResizing ? 'nwse-resize' : 'pointer'
                            }}
                            onClick={(e) => {
                                if (!isResizing) {
                                    setIsEditing(true);
                                }
                            }}
                        />
                        {isHovered && (
                            <div
                                className="position-absolute"
                                style={{
                                    right: -8,
                                    bottom: -8,
                                    width: 16,
                                    height: 16,
                                    backgroundColor: '#007bff',
                                    cursor: 'nwse-resize',
                                    borderRadius: '50%',
                                    zIndex: 1
                                }}
                                onMouseDown={handleStartResize}
                            />
                        )}
                    </div>
                )}

                {isHovered && !image && (
                    <button 
                        className="btn btn-primary" 
                        onClick={() => setIsEditing(true)}
                    >
                        Add Image
                    </button>
                )}
            </div>

            {isEditing && ReactDOM.createPortal(
                <div 
                    className="modal show fade d-block" 
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {image ? "Edit Image" : "Add Image"}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={closeEditor}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                {image && (
                                    <div className="mb-4 text-center">
                                        <img
                                            src={image.src.startsWith("http") || image.src.startsWith("https") 
                                                ? image.src 
                                                : `${API_URL}/web-builder/${image.src}`}
                                            alt={image.alt || "Preview"}
                                            style={{ 
                                                maxWidth: "100%", 
                                                maxHeight: "200px", 
                                                objectFit: "contain", 
                                                borderRadius: "8px" 
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="mb-3">
                                    <label className="form-label">Upload New Image</label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileUpload}
                                        className="form-control"
                                        disabled={isUploading}
                                    />
                                    {isUploading && (
                                        <div className="mt-2">
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: `${uploadProgress}%` }}
                                                    aria-valuenow={uploadProgress}
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                >
                                                    {uploadProgress}%
                                                </div>
                                            </div>
                                            <small className="text-muted mt-1 d-block">
                                                Uploading image...
                                            </small>
                                        </div>
                                    )}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Alt Text</label>
                                    <input
                                        type="text"
                                        value={altText}
                                        onChange={(e) => setAltText(e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={closeEditor}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                    disabled={isUploading}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default SingleImageEditor;