import React, { useEffect,useState,useCallback } from 'react'
import { useBuilderContext } from '../../builderContext';
import SocialSharePage from "../../Components/share/SocialShare";
import BuilderHeader from "../../PageBuilder/Components/TemplateView/BuilderHeader";
import Event from "./flexComponents/Event";
import { API_URL, REACT_APP_WEBSITE } from "../../BackendApi/Api/config";
import EventParking from "./flexComponents/EventParking";
import EventAgenta from "./flexComponents/EventAgenta";
import EventsImagesList from "./flexComponents/EventsImagesList";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import EventsGalleryList from "./flexComponents/EventsGalleryList";
import { FaFacebook, FaInstagram, FaLinkedin, FaNetworkWired, FaPinterest, FaTiktok, FaTwitter } from 'react-icons/fa';
import { BsGlobe2 } from 'react-icons/bs';
import { AiFillMail } from 'react-icons/ai';


const EventPage = ({ brandsocials, renderComponent, url, renderAnimations, smartlink_id, brand_name, brand_img, }) => {
    let { myComponents, fontFamily, buttonColor, } = useBuilderContext();
    const [formData, setFormData] = React.useState(null);
    const [dataProps, setDataProps] = React.useState(null);
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState(null);
    const [modeldata, setModeldata] = useState(null);
    const socials = [
        { platform: "pinterest", icon: <FaPinterest size={16} />, link: brandsocials?.pinterestLink },
        { platform: "instagram", icon: <FaInstagram size={16} />, link: brandsocials?.instagramLink },
        { platform: "facebook", icon: <FaFacebook size={16} />, link: brandsocials?.facebookLink },
        { platform: "linkedin", icon: <FaLinkedin size={16} />, link: brandsocials?.linkedinLink },
        { platform: "twitter", icon: <FaTwitter size={16} />, link: brandsocials?.twitterLink },
        { platform: "website", icon: <BsGlobe2 size={16} />, link: brandsocials?.websiteLink },
        { platform: "tiktok", icon: <FaTiktok size={16} />, link: brandsocials?.tiktokLink },
        { platform: "other", icon: <FaNetworkWired size={16} />, link: brandsocials?.otherLink },

    ];

    let hostName = dataProps?.hostName ? dataProps?.hostName : "Host Name";
    let hostedImage = dataProps?.hostedImage ? dataProps?.hostedImage : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600";
 
    useEffect(() => {
            myComponents.map((item, index) => {
                if (item.type === "EVENT") {
                    setDataProps(item.properties)
                }
            })
    }, [])
const handleShowModel = (types,MData) => {
    setShowModal(true);
    setType(types);
    setModeldata(MData);

    }
    const handleClose = () => {
        setShowModal(false);
    };
    return (
        <div className='event-pub-page' style={{ fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif' }}>
            <div
                className="preview-box-background"
            >
                {renderAnimations()}

                <style>
                    {`
                        .event-pub-page .builder-box-design{
                            width: 1020px !important;
                            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
                        }
                        `}
                </style>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>

                    <SocialSharePage
                        type="portfolio"
                        url={url.href}
                        title="Check out this Page"
                        description="I found this great website and thought you might like it too."
                    />
                </div>
                <div className={`page_builder_design_body home-preview-body`}  >
                    <div className={`builder-box-design public_flex_design goWHMr`}

                        style={{ fontFamily: fontFamily, backgroundColor: "transparent", boxShadow: "none", overflow: "auto", margin: "0 auto", marginBottom: 80 }}
                    >

                        <div className="builder_content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        {myComponents.map(
                                            (component, index) =>
                                                component.type === "HEADER" && (
                                                    <BuilderHeader
                                                        key={index}
                                                        types={true}
                                                        data={component.properties}
                                                    />
                                                )
                                        )}
                                        <div style={{ display: "flex", gap: 10, width: "100%", marginBottom: "10px" }}>
                                            <div>
                                                <img
                                                    src={(brand_img?.startsWith("http://") || brand_img?.startsWith("https://")) ? brand_img : API_URL + "flowpage/" + brand_img}
                                                    style={{ width: "50px", height: "auto", borderRadius: 4 }} alt={"data.imageAltText"} />
                                            </div>
                                            <div>
                                                <p className="m-0 light-txt">
                                                    Presented by <br />
                                                    <a href={`${REACT_APP_WEBSITE}brand-events/${brand_name}`} style={{ color: "white", textDecoration: "none", fontSize: "16px", fontWeight: "600" }} target="_blank" rel="noopener noreferrer">{brand_name}<MdOutlineKeyboardArrowRight className='ms-1'/></a>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex light-txt mt-2">
                                                {socials.map(
                                                    ({ platform, icon, link }, index) =>
                                                        link && (
                                                            <div
                                                                key={platform}
                                                                onClick={() => window.open(link, "_blank")}
                                                                className="me-2"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                {icon}
                                                            </div>
                                                        )
                                                )}
                                            </div>

                                            {(formData?.showHosts && (hostName || hostedImage)) &&
                                                <div className="event-description-sec mt-3">
                                                    <div className="event-description-title event-xyt" style={{ color: "white", fontWeight: "700", fontSize: "14px" }}>
                                                        Hosted By
                                                    </div>
                                                    <div>


                                                        {Array.isArray(hostedImage) ? (
                                                            hostedImage.map((image, index) => (
                                                                <>
                                                                    <div style={{ marginTop: "20px", display: "flex", margin: 10, color: "white" }}>
                                                                        <img
                                                                            key={index}
                                                                            src={API_URL + "profile/" + image}
                                                                            alt={`host-${index}`}
                                                                            style={{
                                                                                marginTop: '4px',
                                                                                width: '24px',
                                                                                height: '24px',
                                                                                borderRadius: '50%',
                                                                                marginRight: '8px'
                                                                            }}
                                                                        />
                                                                        <div className="hosted-text">
                                                                            <div className="hosted-name">
                                                                                {hostName[index]}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))
                                                        ) : (
                                                            <>
                                                                <div style={{ marginTop: "20px", display: "flex", margin: 10, color: "white" }}>
                                                                    <img
                                                                        src={API_URL + "profile/" + hostedImage}
                                                                        alt="host"
                                                                        style={{
                                                                            marginTop: '4px',
                                                                            width: '24px',
                                                                            height: '24px',
                                                                            borderRadius: '50%',
                                                                            marginRight: '8px'
                                                                        }}
                                                                    />
                                                                    <div className="hosted-text">
                                                                        <div className="hosted-name">
                                                                            {hostName}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                    </div>
                                                    <div className="d-flex light-txt" style={{ flexDirection: "column", display: "flex", fontWeight: "600", fontSize: "14px" }}>
                                                        <span onClick={() => handleShowModel("contact", dataProps,)} >Contact the Host</span>
                                                        <span>Report Event</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-8" style={{ color: "white" }}>
                                        {myComponents.map((component, index) =>
                                            component.type !== "HEADER" && component?.showStatus === true ? (
                                                <React.Fragment key={index}>
                                                    {component.type === "EVENT" ? (
                                                        <Event
                                                            handleShowModel={handleShowModel}
                                                            showModal={showModal}
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                            setFormData={setFormData}
                                                            type={type}
                                                            modeldata={modeldata}
                                                            handleClose={handleClose}
                                                            brand_name={brand_name}
                                                            brand_img={brand_img}
                                                            types={true}
                                                        />
                                                    ) : component.type === "AGENTA" ? (
                                                        <EventAgenta
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : component.type === "IMAGES" ? (
                                                        <EventsImagesList
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : component.type === "GALLERY" ? (
                                                        <EventsGalleryList
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : component.type === "PARKING" ? (
                                                        <EventParking
                                                            smartlink_id={smartlink_id}
                                                            data={component.properties}
                                                        />
                                                    ) : null}
                                                </React.Fragment>
                                            ) : null
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventPage

