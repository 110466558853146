import React, { useEffect, useState } from 'react'
import '../DiscoverEvents/DiscoverEvents.css'
import DiscoverEventsNav from '../../Components/DiscoverEventsNav/DiscoverEventsNav'
import { FiClock } from 'react-icons/fi';
import { IoMdAdd } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { getCityEvents } from '../../BackendApi/Api/Event';
import { useParams } from 'react-router-dom';
import { API_URL } from "../../BackendApi/Api/config";
import TimeLine from '../../Components/TimeLine/TimeLine';
function CityEvents() {
    const [time, setTime] = useState(new Date());
    const [cityData, setCityData] = useState({});
    const [eventData, setEventData] = useState([]);
    const { city_slug } = useParams()
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const getEventsCity = async () => {
        try {
            const result = await getCityEvents({ city_slug: city_slug });

            if (result.data.status) {
                console.log(result.data.data);
                setCityData(result.data.city);
                setEventData(result.data.data);
            } else {
                console.error(result.data.message || "Unknown error occurred");
            }
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    }

    useEffect(() => {
        if (city_slug) {
            getEventsCity();
        }
    }, []);
    let city_logo = cityData?.city_logo ? `${API_URL}eventcity/${cityData?.city_logo}` : "https://images.lumacdn.com/discovery/miami-icon.png";
    let city_banner_img = cityData?.banner_image ? `${API_URL}eventcity/${cityData?.banner_image}` : "https://images.lumacdn.com/cdn-cgi/image/format=auto,fit=cover,dpr=2,background=white,quality=75,width=1080/discovery/newdelhi-desktop.jpg";
    return (
        <div className='city-events'>
            <div className="city-hero-container" style={{ backgroundImage: `url(${city_banner_img})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                <div>
                    <DiscoverEventsNav />
                    <section className='city-events-banner'>
                        <div className="container">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-6">
                                    <div className="city-pic d-flex justify-content-center">
                                        <img src={city_logo} alt="logo" className="img-fluid" />
                                    </div>
                                    <div className="city-title">
                                        <h2 className='hero-cityhead'>What’s Happening in</h2>
                                        <h1 className='hero-city-name'>{cityData?.city_name}</h1>
                                    </div>
                                    <div className="city-time d-flex align-items-center pb-3">
                                        <FiClock className='me-2' />
                                        {time.toLocaleTimeString("en-US", {
                                            timeZone: "Asia/Kolkata",
                                            hour12: true,
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })} GMT+5:30
                                    </div>
                                    <hr />
                                    <div className="city-desc text-white py-3">
                                        <p>{cityData?.city_description ? cityData?.city_description : "A rising tech hub, Miami hosts events ranging from aerospace happy hours to AI and crypto summits, fostering innovation and connections in the ecosystem."}</p>
                                    </div>
                                    <div className="city-email d-flex align-items-center">
                                        <input type="email" className="e-mail custom-placeholder me-3" placeholder="you@gmail.com" id="email" style={{ backgroundColor: 'rgba(255, 255, 255, 0.08', borderRadius: '100px', padding: '10px 34px', border: 'none', outline: 'none', fontSize: '16px' }} />
                                        <a style={{ backgroundColor: 'rgba(255, 255, 255, 0.9', borderRadius: '100px', padding: '10px 14px', color: 'black', fontSize: '16px', fontWeight: '600' }}>Subscribe</a>
                                    </div>
                                </div>
                                <div className="col-lg-6"></div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <section className='city-events-sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9 ">
                            <div className='d-flex align-items-center justify-content-between'>
                                <h2 className='city-events-title'>Events</h2>
                                <div className="d-flex align-items-center gap-2">
                                    <a href="#" className="city-events-link d-flex align-items-center light-bg-btn"><IoMdAdd className='me-1' />Submit Event</a>
                                    <div className='d-flex align-items-center light-bg-btn small-btn'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path d="M4 11a9 9 0 0 1 9 9M4 4a16 16 0 0 1 16 16"></path><circle cx="5" cy="19" r="1"></circle></svg>
                                    </div>
                                    <div className="d-flex align-items-center light-bg-btn small-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M12 7A5 5 0 1 1 2 7a5 5 0 0 1 10 0m-.965 5.096a6.5 6.5 0 1 1 1.06-1.06l2.935 2.934a.75.75 0 1 1-1.06 1.06z"></path></svg>
                                    </div>
                                </div>
                            </div>
                            <TimeLine eventData={eventData} />

                        </div>
                        <div className="col-lg-3">
                            {/* <div className="city-events-search">
                                <input type="search" placeholder='Search Events' />
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default CityEvents