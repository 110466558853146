import { useState, useEffect, useRef, useContext } from 'react';
import { PlusCircle, MoreVertical, Trash2, Copy, ImageIcon, Layout, Type, Image, ImagePlus } from 'lucide-react';

import SidebarMenu from './components/sidebar/SidebarMenu';

import ComponentsList from './ComponentsList'

import { WebBuilderContext } from '../../contexts/context/webBuilderContext'

import GenerationLoader from "./GenerationLoader"

const API_URL = process.env.REACT_APP_API_URL;


const BLOCK_TYPES = {
  NONE: { id: 'NONE', icon: Type },
  TOP: { id: 'TOP', icon: ImagePlus },
  LEFT: { id: 'LEFT', icon: Layout },
  RIGHT: { id: 'RIGHT', icon: Layout },
  BACKGROUND: { id: 'BACKGROUND', icon: Image }
};

const BlockBuilder = () => {

  const { blockData, webBuilderDispatch } = useContext(WebBuilderContext);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);


  // Combined state for active menus
  const containerRef = useRef(null); // Reference for the menu container
  const menuRef = useRef(null); // Reference for the menu
  const blockContainerRef = useRef(null); // Ref to the block container

  useEffect(() => {
    console.log("blockData", blockData.blocks);

  }, []);

  const handleFileUpload = async (e, blockId) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('image', file);

    try {
      const interval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 90) {
            clearInterval(interval);
            return 90;
          }
          return prev + 10;
        });
      }, 300);

      const response = await fetch(`${API_URL}/web-builder/upload-image`, {
        method: 'POST',
        body: formData
      });

      clearInterval(interval);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.status) {
        webBuilderDispatch({
          type: 'UPDATE_BLOCK_IMAGE',
          payload: {
            id: blockId,
            image: {
              src: data.data.filename,
              alt: file.name
            }
          }
        });
      } else {
        throw new Error(data.message);
      }

      setUploadProgress(100);
      setTimeout(() => {
        setIsUploading(false);
        setUploadProgress(0);
      }, 500);

    } catch (error) {
      console.error('Upload failed:', error);
      setIsUploading(false);
      setUploadProgress(0);
      alert('Failed to upload image. Please try again.');
      e.target.value = '';
    }
  };

  const addBlock = (index) => {

    webBuilderDispatch({ type: 'ADD_BLOCK', payload: { index } });
  };

  const removeBlock = (id) => {
    webBuilderDispatch({ type: 'REMOVE_BLOCK', payload: { id } });
  };

  const duplicateBlock = (block, index) => {
    webBuilderDispatch({ type: 'DUPLICATE_BLOCK', payload: { index: index } });
  };

  const changeBlockType = (id, newType) => {

    webBuilderDispatch({ type: 'CHANGE_BLOCK_TYPE', payload: { id: id, blockType: newType } })

  };

  const handleBlockColorChange = (blockId, newColor) => {

    webBuilderDispatch({ type: 'UPDATE_BLOCK_COLOR', payload: { id: blockId, color: newColor } })
  };


  // Helper function to toggle menus
  const toggleMenu = (menuType, blockId) => {

    webBuilderDispatch({ type: 'TOGGLE_MENU', payload: { menuType, blockId } })

  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the block container
      if (blockContainerRef.current && !blockContainerRef.current.contains(event.target)) {
        console.log('Clicked outside the blocks');
        webBuilderDispatch({ type: 'REMOVE_ACTIVE_BLOCK', payload: {} });
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) && // Ensure the click is outside the container
        menuRef.current &&
        !menuRef.current.contains(event.target) // Ensure the click is outside the menu
      ) {
        webBuilderDispatch({ type: 'CLOSE_MENU', payload: {} })
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="mainbuilder flex flex-col  min-w-4xl  ">
        <div>
          {blockData.blocks.map((block, index) => (
            <div key={block.id}
              className="block-wrapper border border-2 border-dashed rounded-0 block-container py-2"
              style={{
                backgroundColor: block.color || '#ffffff', // Default to white
              }}
              onClick={() => {
                webBuilderDispatch({ type: 'UPDATE_BLOCK_ID', payload: { blockId: block.id } });
              }}
            >
              <div className="position-relative container">
                <div className="position-absolute top-0 start-0 mt-2 ms-2 d-flex action-buttons" style={{ zIndex: 10 }} ref={containerRef}>
                  {/* Style Button */}
                  <button
                    onClick={() => toggleMenu('style', block.id)}
                    className={`btn btn-link ${blockData.activeMenu.type === 'style' && blockData.activeMenu.blockId === block.id ? 'text-primary' : 'text-secondary'} me-2`}
                  >
                    <Layout size={20} />
                  </button>

                  {/* More Options Button */}
                  <button
                    onClick={() => toggleMenu('options', block.id)}
                    className={`btn btn-link ${blockData.activeMenu.type === 'options' && blockData.activeMenu.blockId === block.id ? 'text-primary' : 'text-secondary'}`}
                  >
                    <MoreVertical size={20} />
                  </button>


                  {blockData.activeMenu.type === 'style' && blockData.activeMenu.blockId === block.id && (
                    <div
                      className="position-absolute bg-white shadow-sm border rounded p-3"
                      style={{ left: '0', top: '100%', width: '16rem' }}
                      ref={menuRef} // Attach ref for the menu

                    >
                      <div className="d-flex flex-column gap-3">
                        {/* Layout Selection */}
                        <div className="d-flex gap-2 justify-content-between">
                          {Object.values(BLOCK_TYPES).map((type) => {
                            const IconComponent = type.icon;
                            return (
                              <button
                                key={type.id}
                                onClick={() => {
                                  changeBlockType(block.id, type.id)
                                }}
                                className={`btn btn-sm ${block.type === type.id ? 'btn-primary' : 'btn-outline-secondary'
                                  }`}
                              >
                                <IconComponent size={16} />
                              </button>
                            );
                          })}
                        </div>

                        {/* Background Image Option */}
                        <div>
                          <label className="form-label">Block Image</label>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileUpload(e, block.id)}
                            className="form-control"
                            disabled={isUploading}
                          />
                          {isUploading && (
                            <div className="mt-2">
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${uploadProgress}%` }}
                                  aria-valuenow={uploadProgress}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {uploadProgress}%
                                </div>
                              </div>
                              <small className="text-muted mt-1 d-block">
                                Uploading image...
                              </small>
                            </div>
                          )}
                          {block.image && (
                            <div className="mt-2">
                              <img
                                src={block.image.src.startsWith('http') ?
                                  block.image.src :
                                  `${API_URL}/web-builder/${block.image.src}`}
                                alt={block.image.alt || 'Block image'}
                                className="img-thumbnail"
                                style={{ maxHeight: '100px' }}
                              />
                            </div>
                          )}
                        </div>

                        {/* Add Block Color Option */}
                        <div>
                          <label className="form-label">Block Color</label>
                          <input
                            type="color"
                            className="form-control form-control-color"
                            value={block.color || '#ffffff'} // Default to white if no color is set
                            onChange={(e) => handleBlockColorChange(block.id, e.target.value)}
                          />
                        </div>

                        {/* Overlay Effect */}
                        <div>
                          <label className="form-label">Overlay effect</label>
                          <select className="form-select">
                            <option value="none">None</option>
                            <option value="faded">Faded</option>
                          </select>
                        </div>

                        {/* Overlay Color */}
                        <div>
                          <label className="form-label">Overlay color</label>
                          <input type="color" className="form-control form-control-color" />
                        </div>

                        {/* Full-Bleed Toggle */}
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`fullBleedToggle-${block.id}`}
                            checked={true}
                          />
                          <label className="form-check-label" htmlFor={`fullBleedToggle-${block.id}`}>
                            Full-bleed card
                          </label>
                        </div>

                        {/* Content Alignment */}
                        <div>
                          <label className="form-label">Content alignment</label>
                          <div className="btn-group">
                            <button className="btn btn-sm btn-outline-secondary">Left</button>
                            <button className="btn btn-sm btn-outline-secondary">Center</button>
                            <button className="btn btn-sm btn-outline-secondary">Right</button>
                          </div>
                        </div>

                        {/* Card Width */}
                        <div>
                          <label className="form-label">Card width</label>
                          <div className="btn-group">
                            <button className="btn btn-sm btn-outline-secondary">M</button>
                            <button className="btn btn-sm btn-outline-secondary">L</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                  {/* More Options Dropdown */}
                  {blockData.activeMenu.type === 'options' && blockData.activeMenu.blockId === block.id && (
                    <div className="position-absolute bg-white shadow-sm border rounded py-1"
                      style={{ left: '0', top: '100%' }}>
                      <button
                        className="btn btn-link text-secondary d-flex align-items-center w-100 px-3"
                        onClick={() => duplicateBlock(block, index)}
                      >
                        <Copy size={16} className="me-2" /> Duplicate
                      </button>
                      <button
                        className="btn btn-link text-danger d-flex align-items-center w-100 px-3"
                        onClick={() => removeBlock(block.id)}
                      >
                        <Trash2 size={16} className="me-2" /> Delete
                      </button>
                    </div>
                  )}
                </div>

                {
                  block.type === BLOCK_TYPES.TOP.id &&
                  <div className="d-flex flex-column">
                    <div className="w-100 bg-light d-flex align-items-center justify-content-center" style={{ height: '12rem' }}>
                      <ImageIcon className="text-secondary" size={32} />
                    </div>
                    {/* <div className="p-3 text-secondary text-center"> */}
                    <div className="p-5">
                      <ComponentsList blockId={block.id} content={block.content} />
                    </div>

                  </div>
                }

                {
                  block.type === BLOCK_TYPES.LEFT.id &&
                  <div className="row g-0">
                    <div className="col-4 py-5 bg-light d-flex align-items-center justify-content-center" style={{ height: '12rem' }}>
                      <ImageIcon className="text-secondary" size={32} />
                    </div>
                    <div className="col-8 p-5 text-secondary d-flex flex-column align-items-center justify-content-center">
                      <ComponentsList blockId={block.id} content={block.content} />

                    </div>

                  </div>
                }

                {
                  block.type === BLOCK_TYPES.RIGHT.id &&
                  <div className="row g-0">
                    <div className="col-8 p-5 text-secondary d-flex flex-column align-items-center justify-content-center">
                      <ComponentsList blockId={block.id} content={block.content} />

                    </div>

                    <div className="col-4 p-5 bg-light d-flex align-items-center justify-content-center" style={{ height: '12rem' }}>
                      <ImageIcon className="text-secondary" size={32} />
                    </div>
                  </div>
                }

                {
                  block.type === BLOCK_TYPES.BACKGROUND.id &&
                  <div className="position-relative" style={{ height: '12rem' }}>
                    <div className="position-absolute top-0 start-0 end-0 bottom-0 bg-light d-flex align-items-center justify-content-center">
                      <ImageIcon className="text-secondary" size={32} />
                    </div>
                    <div className="position-relative h-100 d-flex align-items-center justify-content-center text-secondary">
                      <ComponentsList blockId={block.id} content={block.content} />

                    </div>

                  </div>
                }

                {
                  block.type === BLOCK_TYPES.NONE.id &&
                  <div className="p-3 text-secondary text-center">
                    <ComponentsList blockId={block.id} content={block.content} />
                  </div>
                }
              </div>

              <div className="position-relative" style={{ height: '0' }}>
                <button
                  onClick={() => addBlock(index)}
                  className="position-absolute start-50 translate-middle add-button"
                  style={{ background: 'white' }}
                >
                  <PlusCircle size={24} className="text-secondary hover-primary" />
                </button>
              </div>
            </div>
          ))}

          {blockData.blocks.length === 0 && (
            <div className="empty-state">
              <div className="border border-2 border-dashed rounded p-4 text-secondary text-center" style={{ minHeight: '100px' }}>
                No blocks yet
                <div className="mt-3">
                  <button
                    onClick={() => addBlock()}
                    className="btn btn-outline-primary"
                  >
                    <PlusCircle size={20} className="me-2" /> Add Block
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <style>
          {`
          .block-container {
            transition: border-color 0.2s;
          }
          
          .block-container:hover {
            border-color: var(--bs-primary) !important;
          }
          
          .action-buttons {
            opacity: 0;
            transition: opacity 0.2s;
          }
          
          .block-wrapper:hover .action-buttons {
            opacity: 1;
          }
          
          .add-button {
            opacity: 0;
            transition: opacity 0.2s;
            border: none;
            padding: 0;
            margin-top: -12px;
          }
          
          .block-wrapper:hover .add-button,
          .add-button:hover {
            opacity: 1;
          }
          
          .hover-primary:hover {
            color: var(--bs-primary) !important;
          }
          
          .btn-link {
            text-decoration: none;
            padding: 0;
          }
          
          .btn-link:hover {
            color: var(--bs-primary) !important;
          }
        `}
        </style>
      </div>
      <SidebarMenu />
      {
        blockData.isGenerating && <GenerationLoader />
      }

    </>
  );
};









export default BlockBuilder;