import React from "react";

export default function EventParking({ data }) {
    const description = data.parkingDetails;

    return (
        <>
        {/* <div className="d-flex">
            <div style={{ backgroundColor: "transparent", padding: "" }}>
                <h3 style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#fff", // Dark color for heading
                    marginBottom: "10px"
                }}>
                    {data.heading}
                </h3>
                <div
                    style={{
                        color: "#fff",  // Ensure the text is white
                        backgroundColor: "transparent",
                        whiteSpace: "pre-wrap",  // Ensure line breaks are respected
                        wordWrap: "break-word",  // Break long words if needed
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
            </div> */}
            <div className="event-description-sec mt-3">
                <div className="event-description-title event-xyt">
                    {data.heading}
                </div>
                <div className="event-description-text">
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>
            </div>
            </>
    );
}
