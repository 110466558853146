import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./Header.css"
import { FiChevronDown } from 'react-icons/fi';
import { BsGrid, BsPower } from "react-icons/bs";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {

    const [show, setShow] = useState("");

    const [scroll, setScroll] = useState(false);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 40);
        });
    }, []);

    const toggle = () => {
        if (show == "") {
            setShow("show");
        } else {
            setShow("");
        }
    }


    const LoggedInData = localStorage.getItem('user');
    const data = JSON.parse(LoggedInData);
    console.log(data);


    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.href = "/";
    }
    const userdata = localStorage.getItem("user");
    const user = JSON.parse(userdata);

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="bg-color-nav nav-bar-width">
                <Container fluid className="inner-padding">
                    <Navbar.Brand href="#home">
                        <img src={require('../../landingassets/Images/logo.png')} alt="logo" />
                    </Navbar.Brand>



                    {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}

                    {/* <Nav className="ms-auto">
                            <Nav.Link className="nav-link-items" href="#features">Blog</Nav.Link>
                            <Nav.Link className="nav-link-items" href="#pricing">Contact</Nav.Link>
                        </Nav> */}
                    {data === null ? (
                        <Nav>
                            <Link to="/login" className="target-link">
                                <Nav.Link href="#deets" className="nav-btn-liner">Sign in</Nav.Link>
                            </Link>
                            <Link to="/createaccount" className="target-link">
                                <Nav.Link href="#deets" className="nav-btn-color">
                                    Sign up
                                </Nav.Link>
                            </Link>
                        </Nav>
                    ) : (
                        <Nav>
                            <Link to="/events" className="target-link">
                                <Nav.Link href="#deets" className="nav-btn-liner">Events</Nav.Link>
                            </Link>
                            <div className={`sec-center d-flex align-items-center ${show}`}>
                                <label className="for-dropdown profile-btn-view" htmlFor="dropdown" onClick={toggle}>
                                    <div className="user-image"> <img src={require('../../landingassets/Images/user-profile.jpg')} className="img-responsive modal_profile_img2" /></div>
                                    <span className="user-name m-0">{(JSON.parse(localStorage.getItem('user')).fname)} {(JSON.parse(localStorage.getItem('user')).lname)}</span>
                                    <FiChevronDown />
                                </label>
                                <div className="section-dropdown dropdown-menu-item">
                                    <a className="dropdown-item memu-drop-item" href={user.role == "1" ? "/admindashboard" : "/dashboard"}><BsGrid /> Dashboard</a>
                                    <a className="dropdown-item memu-drop-item" href="/" onClick={logout}><BsPower /> Logout</a>
                                </div>
                            </div>
                        </Nav>
                    )}
                </Container>
            </Navbar >
        </>
    );
}

export default Header;
