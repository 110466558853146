import React, { useEffect, useState, useContext } from 'react'
import { FiArrowUpRight } from "react-icons/fi";
import './DiscoverEvents.css'
import { IoLocationOutline } from "react-icons/io5";
import { getEventsCities } from '../../BackendApi/Api/Event';
import { REACT_APP_WEBSITE } from "../../BackendApi/Api/config";
import useEventCategoryActions from '../../contexts/actions/useEventCategoryActions';
import { eventCategoryContext } from '../../contexts/context/eventCategoryContext';
import { useHistory } from 'react-router-dom'
import DiscoverEventsNav from '../../Components/DiscoverEventsNav/DiscoverEventsNav';

const API_URL = process.env.REACT_APP_API_URL;

function DiscoverEvents() {

    const history = useHistory();
    const { getAllEventCategories } = useEventCategoryActions();
    const { eventCategoryData } = useContext(eventCategoryContext);

    const [time, setTime] = useState(new Date());
    const [activeTab, setActiveTab] = useState('asia');
    const [cityData, setCityData] = useState([]);
    const getEventsCity = async () => {
        try {
            const response = await getEventsCities();
            console.log("Response:", response.data);

            if (response.data.status) {
                setCityData(response.data.data);

            } else {
                console.error(response.data.message || "Unknown error occurred");
            }
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);


    useEffect(() => {
        getEventsCity();
        getAllEventCategories();
    }, []);

    return (
        <>
            <div className='discover-events'>
                <DiscoverEventsNav />

                {/* Home Section */}
                <section id="home" className="py-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 text-start">
                                <h1 className="display-4 text-white main-heading">Discover Events</h1>
                                <div className="lead sub-main-txt">
                                    Explore popular events near you, browse by category, or check out some of the great community calendars.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* About Section */}
                <section id="about" className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="sub-heading">Explore Local Events</h2>
                                <div className="d-flex align-items-center mt-3">
                                    {/* <div className="d-flex list-cities me-2"> */}
                                        {/* <span onClick={() => setActiveTab('asia')}
                                            className={`tab-icon ${activeTab === 'asia' ? 'active-tab' : ''} mb-0 `}
                                        >
                                            Asia & Pacific
                                        </span>
                                        <span onClick={() => setActiveTab('europe')}
                                            className={`tab-icon ${activeTab === 'europe' ? 'active-tab' : ''} mb-0 `}
                                        >
                                            Europe
                                        </span>
                                        <span onClick={() => setActiveTab('africa')}
                                            className={`tab-icon ${activeTab === 'africa' ? 'active-tab' : ''} mb-0 `}
                                        >
                                            Africa
                                        </span>
                                        <span onClick={() => setActiveTab('americas')}
                                            className={`tab-icon ${activeTab === 'americas' ? 'active-tab' : ''} mb-0 `}
                                        >
                                            Americas
                                        </span> */}
                                        <div className="cities row gx-2 mt-4">
                                            {Array.isArray(cityData) && cityData.map((city, index) => {
                                                return (
                                                    <>

                                                        <div className="col-md-3 mb-5 tab-icon"
                                                            onClick={() => history.push(`city-events/${city?.city_slug}`)}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <img src="bangkok-icon.png" alt="" />
                                                                <div className="city-name ms-2">
                                                                    <h3>{capitalizeWords(city?.city_name || '')}</h3>
                                                                    <p>
                                                                        {city?.cityEventsCount || 0} Events
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            })}
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>


                    </div>
                </section>

                {/* Services Section */}
                <section id="services" className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="sub-heading">Explore By Category</h3>
                            </div>
                        </div>
                        <div className="row mt-4">

                            {Array.isArray(eventCategoryData.categories) && eventCategoryData.categories.map((category, index) => {


                                return (
                                    <div className="col-md-4"
                                        onClick={() => history.push(`categorical-events/${category?.category_slug}`)}
                                    >
                                        <div className="discover-card">
                                            <div className="card-body">
                                                <img src={`${API_URL}/eventcategory/${category.category_logo}`} alt="" />
                                                <h5 className="card-title">{category.category_name}</h5>
                                                <p className="card-text">{category?.events || 0} Events</p>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })}

                            {/* <div className="col-md-4">
                                <div className="discover-card">
                                    <div className="card-body">
                                        <img src="arts-icon.png" alt="" />
                                        <h5 className="card-title">Card title</h5>
                                        <p className="card-text">538 Events</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="discover-card">
                                    <div className="card-body">
                                        <img src="arts-icon.png" alt="" />
                                        <h5 className="card-title">Card title</h5>
                                        <p className="card-text">538 Events</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="discover-card">
                                    <div className="card-body">
                                        <img src="arts-icon.png" alt="" />
                                        <h5 className="card-title">Card title</h5>
                                        <p className="card-text">538 Events</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                {/* <section id="services" className="mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="sub-heading">Explore By Category</h3>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <div className="calendar-card">
                                    <div className="card-body">
                                        <div className='line-1 d-flex align-items-start justify-content-between'>
                                            <img src="calendar.avif" alt="" />
                                            <a style={{ backgroundColor: 'rgba(255, 255, 255, 0.08', borderRadius: '100px', padding: '7px 10px', color: 'rgba(255, 255, 255, 0.64)', fontSize: '14px' }}>Subscribe</a>
                                        </div>
                                        <div>
                                            <h5 className="card-title">#HKWeb3 Events Calendar</h5>
                                        </div>
                                        <div>
                                            <span className="card-text">
                                                <span className='location'>
                                                    <span>
                                                        <IoLocationOutline />
                                                    </span>
                                                    <span>
                                                        Hong Kong
                                                    </span>
                                                    <span className=''>&middot;</span>
                                                </span>
                                                <span className='desc'>The Hong Kong Web3 calendar of events - cu...</span>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section> */}

            </div>
        </>
    )
}

export default DiscoverEvents