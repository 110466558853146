import React, { createContext, useContext, useReducer, useMemo } from 'react';

import { webBuilderReducer } from '../reducers/webBuilderReducer';

export const WebBuilderContext = createContext();

const initialState = {
  blocks: [],
  activeMenu: { type: null, blockId: null },
  activeBlock: { blockId: null, contentId: null },
  isGenerating: false,
};



export function WebBuilderProvider({ children }) {
    const [state, webBuilderDispatch] = useReducer(webBuilderReducer, initialState);

    const blockData = useMemo(() => state, [state]);
  
    return (
      <WebBuilderContext.Provider value={{ blockData, webBuilderDispatch }}>
        {children}
      </WebBuilderContext.Provider>
    );
  }