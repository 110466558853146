import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { WebBuilderContext } from "../../../../../contexts/context/webBuilderContext";
import { v4 as uuidv4 } from "uuid";
import { FiMoreVertical, FiPlusCircle } from "react-icons/fi";

const API_URL = process.env.REACT_APP_API_URL;

const ImageGrid = ({ blockId, content }) => {
  const { webBuilderDispatch } = useContext(WebBuilderContext);
  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState(null);
  const [altText, setAltText] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageClick = (image) => {
    setActiveImage(image);
    setAltText(image.alt || "");
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("image", file);

    try {
      // Progress update interval
      const interval = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 90) {
            clearInterval(interval);
            return 90;
          }
          return prev + 10;
        });
      }, 300);

      const response = await fetch(`${API_URL}/web-builder/upload-image`, {
        method: "POST",
        // Remove Content-Type header - browser will set it automatically with boundary
        body: formData,
      });

      // Clear the progress interval
      clearInterval(interval);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("image upload data", data);

      if (data.status) {
        if (activeImage) {
          // If editing existing image
          const updatedImage = {
            ...activeImage,
            src: data.data.filename,
          };

          setActiveImage(updatedImage);
        }
      } else {
        // Add user feedback for error
        throw new Error(data.message);
      }

      // Set upload as complete
      setUploadProgress(100);
      setTimeout(() => {
        setIsUploading(false);
        setUploadProgress(0);
        // closeEditor(); // Close the modal after successful upload
      }, 500);
    } catch (error) {
      console.error("Upload failed:", error);
      setIsUploading(false);
      setUploadProgress(0);

      // Add user feedback for error
      alert("Failed to upload image. Please try again.");

      // Clear the file input
      e.target.value = "";
    }
  };

  const handleSave = () => {
    if (activeImage) {
      setImages((prevImages) =>
        prevImages.map((img) => (img.id === activeImage.id ? activeImage : img))
      );
      webBuilderDispatch({
        type: "UPDATE_GRID_IMAGE",
        payload: { blockId, contentId: content.id, image: activeImage },
      });
    }

    closeEditor();
  };

  const closeEditor = () => {
    setActiveImage(null);
    setAltText("");
  };

  const addImage = () => {
    const newImage = {
      id: `${uuidv4()}-${Math.floor(
        10000000 + Math.random() * 90000000
      ).toString()}-${Date.now()}`,
      src: "https://via.placeholder.com/150",
      alt: "New Image",
    };
    setImages([...images, newImage]);
    webBuilderDispatch({
      type: "ADD_GRID_IMAGE",
      payload: { blockId, contentId: content.id, image: newImage },
    });
  };

  useEffect(() => {
    setImages(content.images);
  }, []);

  return (
    <div
      className="container position-relative my-4"
      onMouseEnter={(e) => {
        setIsHovered(true);
        e.currentTarget.querySelector(".container-hover-border").classList.remove("d-none");
        e.currentTarget.querySelector(".container-three-dot-menu").classList.remove("d-none");
        e.currentTarget.querySelector(".card-plus-menu-icon").classList.remove("d-none");
      }}
      onMouseLeave={(e) => {
        setIsHovered(false);
        e.currentTarget
          .querySelector(".container-hover-border")
          .classList.add("d-none");
        e.currentTarget
          .querySelector(".container-three-dot-menu")
          .classList.add("d-none");
        e.currentTarget
          .querySelector(".card-plus-menu-icon")
          .classList.add("d-none");
      }}
      onClick={() => {
        console.log("content id", content.id);
        webBuilderDispatch({
          type: "UPDATE_CONTENT_ID",
          payload: { contentId: content.id },
        });
      }}
    >
      {/* Hover Border for Container */}
      <div
        className="container-hover-border d-none position-absolute w-100 h-100 border border-2"
        style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
      ></div>

      {/* 3-Dot Menu for Container */}
      <div
        className="container-three-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
        style={{ zIndex: 10, top: "0px", left: "-36px" }}
      >
        <button
          className="btn btn-link text-secondary"
          onClick={() => console.log(`3-dot menu clicked for the container`)}
        >
          <FiMoreVertical size={20} />
        </button>
      </div>
      <div
        className="card-plus-menu-icon d-none position-absolute bg-white shadow-sm rounded"
        style={{
          zIndex: 15,
          right: "0px",
          top: "0px",
          bottom: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isHovered && (
          <button className="btn btn-link text-secondary" onClick={addImage}>
            <FiPlusCircle size={20} />
          </button>
        )}
      </div>
      <div className="row g-4">
        {images.map((image) => (
          <div className="col-6 col-md-4 col-lg-3 " key={image.id}  onClick={() => handleImageClick(image)}>
              <img
                src={
                  image.src.startsWith("http") || image.src.startsWith("https")
                    ? image.src
                    : `${API_URL}/web-builder/${image.src}`
                }
                alt={image.alt || "Image"}
                className="img-fluid rounded shadow-sm"
                style={{ cursor: "pointer", minWidth: "300px" }}
              />
          </div>
        ))}
      </div>

      {/* {isHovered && (
        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-primary" onClick={addImage}>
            Add New Image
          </button>
        </div>
      )} */}

      {activeImage &&
        ReactDOM.createPortal(
          <div
            className="modal show fade d-block"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Image</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeEditor}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3 text-center">
                    <img
                      src={
                        activeImage.src.startsWith("http") ||
                        activeImage.src.startsWith("https")
                          ? activeImage.src
                          : `${API_URL}/web-builder/${activeImage.src}`
                      }
                      alt={activeImage.alt || "Preview"}
                      className="img-fluid mb-2"
                      style={{
                        maxHeight: "150px",
                        objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Upload New Image</label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileUpload}
                      className="form-control"
                      disabled={isUploading}
                    />
                    {isUploading && (
                      <div className="mt-2">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${uploadProgress}%` }}
                            aria-valuenow={uploadProgress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {uploadProgress}%
                          </div>
                        </div>
                        <small className="text-muted mt-1 d-block">
                          Uploading image...
                        </small>
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Alt Text</label>
                    <input
                      type="text"
                      value={altText}
                      onChange={(e) => setAltText(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    disabled={isUploading}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default ImageGrid;
