import React, { useState, useEffect, useContext, useRef } from 'react';
import Header from '../../Components/Header/Header';
// import './Event.css';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { TbEdit, TbTrash, TbChartBar } from 'react-icons/tb';
import { MdPages, MdQrCode, MdEdit } from 'react-icons/md';
import { nanoid } from 'nanoid';
import { API_URL } from '../../BackendApi/Api/config';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import *  as PNotify from '@pnotify/core';
import '@pnotify/core/dist/BrightTheme.css';
import '@pnotify/core/dist/Material.css';
import '@pnotify/core/dist/PNotify.css';
import { FaSearch } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';
import { UserContext } from "../../UserContext.js";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../../Setting/Setting.css";
import "../../../src/FlexpageTemplate/PageBuilder.css"
import { brandSelectionContext } from '../../contexts/context/brandSelectionContext.jsx';
import { BiDuplicate } from 'react-icons/bi';
import CopyCat from '../../Components/CopyCat/CopyCat.js';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { brandSmartTempApi } from '../../BackendApi/Api/brandApi.js';
import { verifybackhalf } from "../../BackendApi/Api/smartsLinkApi";
import useWebBuilderActions  from '../../contexts/actions/useWebBuilderActions';
import { use } from 'react';
import { WebBuilderContext } from '../../contexts/context/webBuilderContext.jsx';
const DEFAULT_FRONTHALF = process.env.REACT_APP_API_URL;
const REACT_APP_WEBSITE = process.env.REACT_APP_WEBSITE;

function WebPagesList() {
    const { subscriptionStatus, subscriptionDetails } = useContext(UserContext);
    const { generateOutlines } = useWebBuilderActions();
    const { webBuilderDispatch } = useContext(WebBuilderContext);

    const { sidebarBrands } = useContext(brandSelectionContext);
    const [campaignSearch, setCampaignSearch] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');
    const [editableTitle, setEditableTitle] = useState('');
    const [pagetitle, setPageTitle] = useState("");
    const [promtTitle, setPromptTitle] = useState("");
    const [brandData, setBrandData] = useState([]);
    const [BrandId, setBrandId] = useState("");
    const [templateData, setTemplateData] = useState([]);
    const [flexTemplateData, setFlexTemplateData] = useState([]);
    const [domainData, setDomainData] = useState([]);
    const [frontHalf, setFrontHalf] = useState(DEFAULT_FRONTHALF);
    const [templateImage, settemplateImage] = useState("");
    const [loading, setLoading] = useState(true);
    const [uniqueId, setUniqueId] = useState("");
    const [backhalfVerfied, setBackhalfVerfied] = useState(false);
    const [brandsOwner_id, setBrandsOwner_id] = useState("");
    const [selectTemp, setSelectTemp] = useState("");
    const [userQuery, setUserQuery] = useState("");




    const [sections, setSections] = useState(() => {
        return [
            {
                "type": "HERO",
                "components": {
                    "background": {
                        "type": "image",
                        "src": ``,
                        "alt": "Hero Background"
                    },
                    "heading": "<h1>Welcome</h1>",
                    "description": "<p>We are a leading supplier of high-quality plywood products. Our plywood is strong, durable and versatile, making it the perfect choice for a wide range of applications.</p>",
                    "buttons": [
                        {
                            "label": "Shop Now",
                            "link": "/shop"
                        },
                        {
                            "label": "Learn More",
                            "link": "/about"
                        }
                    ]
                }
            },
            {
                "type": "HORIZONTAL_CARD",
                "components": {
                    "image": {
                        "type": "image",
                        "src": ``,
                        "alt": "<p>Horizontal Card Image 1</p>"
                    },
                    "title": "<h2>Construction</h2>",
                    "description": "<p>Plywood is a popular choice in construction due to its strength and low cost. It is used in residential construction for floors, walls, roofs and garages. Plywood panels are covered and protected by other materials when used for roofing, and create a subfloor that supports hardwoods, tile and carpet floors."
                }
            },
            {
                "type": "HORIZONTAL_CARD",
                "components": {
                    "image": {
                        "type": "image",
                        "src": ``,
                        "alt": "<p>Horizontal Card Image 2</p>"
                    },
                    "title": "<h2>Furniture</h2>",
                    "description": "<p>Plywood is a popular choice for furniture due to its dimensional stability and resistance to splitting. It can be manufactured in curved shapes, called molded plywood, in which the veneers are bent and shaped as they are glued together. Plywood is also stainable and paintable, making it ideal for shelving, cabinets and furniture."
                }
            },
            {
                "type": "HORIZONTAL_CARD",
                "components": {
                    "image": {
                        "type": "image",
                        "src": ``,
                        "alt": "<p>Horizontal Card Image 3</p>"
                    },
                    "title": "<h2>Engineering</h2>",
                    "description": "<p>Plywood is used as an engineering material for stressed-skin applications. It has been used for marine and aviation applications since WWII, including the British de Havilland Mosquito bomber, and the hulls in the hard-chine Motor Torpedo Boats (MTB) and Motor Gun Boats (MGB). Plywood is also used for packaging materials, scaffolding, sheds and shipping containers."
                }
            },
            {
                "type": "HEADING_AND_TEXT_CARDS",
                "components": {
                    "mainHeading": "<h2>Why Choose Our Plywood</h2>",
                    "textCards": [
                        {
                            "title": "<h3>Versatile</h3>",
                            "description": "<p>Plywood is a versatile material that can be used for a wide range of applications, from construction to furniture to engineering.</p>"
                        },
                        {
                            "title": "<h3>Strong</h3>",
                            "description": "<p>Plywood is strong and durable, making it a popular choice for construction and engineering projects.</p>"
                        },
                        {
                            "title": "<h3>Cost-Effective</h3>",
                            "description": "<p>Plywood is a cost-effective material, especially when compared to natural wood.</p>"
                        }
                    ]
                }
            },
            {
                "type": "IMAGE_CAROUSEL_WITH_HEADING_AND_DESCRIPTION",
                "components": {
                    "images": [
                        {
                            "src": ``,
                            "alt": "Image 1",
                            "heading": "<h3>Plywood Panels</h3>",
                            "description": "<p>Plywood panels are fabricated from multiple layers or plys of softwood veneer glued together with the grain direction of each layer of veneer perpendicular to that of the adjacent layers.</p>"
                        },
                        {
                            "src": ``,
                            "alt": "Image 2",
                            "heading": "<h3>Molded Plywood</h3>",
                            "description": "<p>Plywood can be manufactured in curved shapes, called molded plywood, in which the veneers are bent and shaped as they are glued together.</p>"
                        },
                        {
                            "src": ``,
                            "alt": "Image 3",
                            "heading": "<h3>Exterior Plywood</h3>",
                            "description": "<p>Exterior glued plywood is suitable for outdoor use, but because moisture affects the strength of wood, optimal performance is achieved where the moisture content remains relatively low.</p>"
                        },
                        {
                            "src": ``,
                            "alt": "Image 4",
                            "heading": "<h3>Plywood Furniture</h3>",
                            "description": "<p>Plywood is a popular choice for furniture due to its dimensional stability and resistance to splitting. It is also stainable and paintable, making it ideal for shelving, cabinets and furniture.</p>"
                        }
                    ]
                }
            },
            {
                "type": "TEXT_CAROUSEL_WITH_LOGO_AND_HEADING_AND_DESCRIPTION",
                "components": {
                    "logos": [
                        {
                            "src": ``,
                            "alt": "Logo 1",
                            "heading": "<h3>The Home Depot</h3>",
                            "description": "<p>Plywood is a popular choice in construction because of its strength and low cost. It is used in areas hidden from view that offer support such as floors, walls, roofs and garages in residential construction.</p>"
                        },
                        {
                            "src": ``,
                            "alt": "Logo 2",
                            "heading": "<h3>naturally:wood</h3>",
                            "description": "<p>Plywood is one of the most widely recognized multi-purpose engineered wood-based panel products used in Canadian construction projects. Plywood binds resin and wood fibre sheets to form a composite material sold in panels.</p>"
                        },
                        {
                            "src": ``,
                            "alt": "Logo 3",
                            "heading": "<h3>Britannica</h3>",
                            "description": "<p>Plywood has several advantages over natural wood, especially its dimensional stability and resistance to splitting. For these reasons, it is one of the most important wood products because wood is often used in environments prone to atmospheric changes or exposure to moisture.</p>"
                        }
                    ]
                }
            }
        ];
    });
    const [page_json, setPage_json] = useState('');



    const [show01, setShow01] = useState(false);
    const [show02, setShow02] = useState(false);
    const [show03, setShow03] = useState(false);
    const [show04, setShow04] = useState(false);


    const handleClose01 = () => setShow01(false);
    const handleClose02 = () => setShow02(false);
    const handleClose03 = () => setShow03(false);
    const handleClose04 = () => setShow04(false);

    const handleShow01 = () => setShow01(true);
    const handleShow02 = () => setShow02(true);
    const handleShow03 = () => setShow03(true);
    const handleShow04 = () => setShow04(true);
    const [flex_id, setFlex_id] = useState('');
    let [pageData, setPageData] = useState([]);

    const [title, setTitle] = useState('');
    const [index, setIndex] = useState('');

    let user = localStorage.getItem('user');
    let userdetails = JSON.parse(user);
    let role = userdetails.role;
    let history = useHistory();





    const handleCampaignSearch = (e) => {
        setCampaignSearch(e.target.value);
    }
    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value);
    }
    const handleClearFilter = () => {
        setSelectedFilter('');
    }
    const getWebPageDta = () => {
        setPageData(sections);
    }
    const handleVerifyBackhalf = (e) => {
        const data = {
            backhalf: uniqueId
        }

        verifybackhalf(data).then((res) => {
            if (res.code === 200) {
                if (res.data.status === true) {
                    setBackhalfVerfied(true);
                }
                else {
                    setBackhalfVerfied(false);
                }
            }
            else {
                PNotify.error({
                    title: "error",
                    text: "Something went wrong",
                });
            }
        }
        ).catch((err) => {
            console.log("err", err);
        }
        );

    }
    const handleSelectBrand = () => {
        if (BrandId !== "") {
            const selectedOption = brandData.filter((item) => item.brand_id === BrandId);

            const templateData = selectedOption[0]?.template;
            const domainData = selectedOption[0]?.domain;
            const flexData = selectedOption[0]?.FlexTemplate;

            // If domainData is empty, set it to an empty array
            const domain = domainData?.length > 0 ? domainData : [];

            // Get domain URLs from the .env variable
            const domainUrls = process.env.REACT_APP_DOMAIN_URLS.split(',');

            // Create an array from domain URLs
            const newArray = domainUrls.map((url) => ({ domain_name: url }));

            // Combine newArray with existing domainData
            const finalArray = [...newArray, ...domain];

            // Set the domain data and front half
            setDomainData(finalArray);
            setFrontHalf(finalArray[0]?.domain_name); // Use optional chaining for safety

            // Set the template data and image
            setTemplateData(templateData);
            settemplateImage(templateData[0]?.thumbnail); // Use optional chaining for safety

            // Set the FlexTemplate data
            setFlexTemplateData(flexData);

            // Get brand names and set them for auto-completion
            const brandName = selectedOption.map((item) => item.brand_name);
            // setBrandForAuto(brandName);
            setBrandsOwner_id(selectedOption[0].owner_id);
        }
    };
    const handleDomainSelect = (e) => {
        setFrontHalf(e.target.value);
    }
    const getBrandData = () => {
        setLoading(true);
        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            owner_id: user.owner_id,
        };

        brandSmartTempApi(data)
            .then((res) => {
                if (res.code === 200) {

                    if (res.data.status == true && res.data.data.length > 0) {

                        if (sidebarBrands.selected === "all") {
                            setBrandData(res.data.data);
                            //setSelectTemp(res.data.data);
                            setBrandId(res.data.data[0].brand_id);

                        }
                        else {

                            const filterData = res.data.data.filter((item) => item.brand_id === sidebarBrands.selected);
                            setBrandData(filterData);
                            //setSelectTemp(filterData);
                            setBrandId(filterData[0].brand_id);
                        }

                    }

                } else {
                    // alert("Something went wrong");
                    PNotify.error({
                        title: "error",
                        text: "Something went wrong",
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                // PNotify.error({
                //     title: "error",
                //     text: err.message,
                // });
            })
            .finally((err) => {
                // PNotify.error({
                //     title: "error",
                //     text: err.message,
                // });
                setLoading(false);

            });
    };

    const [saveflexpage, setSaveFlexpage] = useState(false)
    const saveFlexData = async (type, duplicateData) => {
        //long url should be a url
        setSaveFlexpage(true)
        let backhalf; let domainName; let full_short_url; let pageThumbnail;

        let Title; let brandId; let page; let background_color; let button_color; let font_family;

        // const styling = qrOptiions;

        let user_query = userQuery;

        if (type === "new") {
            // const validationResult = SubscribsationBackhalfValid(uniqueId, subscriptionDetails);

            // if (!validationResult.isValid) {
            //     PNotify.error({
            //         title: 'Error',
            //         text: validationResult.message,
            //     });
            //     setSaveFlexpage(false);
            //     return false;
            // }
            if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused") ) {
                PNotify.error({
                    title: 'Error',
                    text: 'Please upgrade your plan to continue',
                });

                setSaveFlexpage(false)
                return false;
            }
            else if (BrandId === "" || BrandId === null || BrandId === undefined) {
                PNotify.error({
                    title: 'Error',
                    text: 'please select brand',

                });
                setSaveFlexpage(false)
                return false;
            }
            else if (frontHalf === "" || frontHalf === null || frontHalf === undefined) {
                PNotify.error({
                    title: 'Error',
                    text: 'please select domain',

                });
                setSaveFlexpage(false)
                return false;
            }
            else if (userQuery === "") {
                PNotify.error({
                    title: 'Error',
                    text: 'please describe your website or business', 

                });
                setSaveFlexpage(false)
                return false;
            }

            else if (title === "" || title === null || title === undefined) {
                PNotify.error({
                    title: 'Error',
                    text: 'title is required',

                });
                setSaveFlexpage(false)
                return false;
            } 
            else {
                // Get the HTML element with the id "capture"
                // var element = document.getElementById("capture");

                // // Remove a specific class from the element
                // element.classList.remove("page_builder_design_body");

                // // Get the reference to the image element
                // const elementToCapture = imageRef.current;

                // // Get the current vertical scroll position
                // const scrollY = window.scrollY;

                // // Hide overflow of the captured element
                // elementToCapture.style.overflow = 'hidden';

                // const dataUrl = await toJpeg(elementToCapture, { scrollY, quality: 3.0 });

                // // Extract base64 data from the data URL
                // const base64Data = dataUrl && typeof dataUrl === 'string' ? dataUrl.replace(/^data:image\/jpeg;base64,/, '') : dataUrl;

                // // Convert base64 to byte array
                // const byteCharacters = atob(base64Data);
                // const byteNumbers = new Array(byteCharacters.length);
                // for (let i = 0; i < byteCharacters.length; i++) {
                //     byteNumbers[i] = byteCharacters.charCodeAt(i);
                // }
                // const byteArray = new Uint8Array(byteNumbers);

                // // Create a Blob from the byte array
                // var blob = new Blob([byteArray], { type: 'image/jpeg' });

                // Assign values to variables
                backhalf = uniqueId;
                domainName = frontHalf;
                full_short_url = frontHalf + "/" + backhalf;
                // styling.data = full_short_url + "?method=scan";
                Title = title;        
                brandId = BrandId;


            }
        }


        // if (type === "duplicate") {

        //     // const id = nanoid(8);
        //     const validationResult = SubscribsationBackhalfValid(uniqueId, subscriptionDetails);

        //     if (!validationResult.isValid) {
        //         PNotify.error({
        //             title: 'Error',
        //             text: validationResult.message,
        //         });
        //         setSaveFlexpage(false);
        //         return false;
        //     }
        //     backhalf = uniqueId;

        //     domainName = domain_name;

        //     full_short_url = domainName + "/" + backhalf;

        //     styling.data = full_short_url + "?method=scan";

        //     pageThumbnail = duplicateData.page_thumbnail;

        //     page = duplicateData.page_json;

        //     Title = title;

        //     background_color = background_colors;

        //     button_color = button_colors;

        //     font_family = font_familys;

        //     brandId = BrandId;

        // }

        const userdata = localStorage.getItem("user");
        const user = JSON.parse(userdata);
        const owner_id = user.owner_id;
        const created_by = user._id;

        // const url = API_URL + updateAutoPage == 1 ? "flowpage/saveflexpagebyauto" : "flowpage/saveminipage";

        const url = API_URL + "web-builder/intialize-web-builder";

        const data = new URLSearchParams();
        data.append("full_short_url", full_short_url);
        data.append("domain_name", domainName);
        // data.append('styling', JSON.stringify(styling));
        data.append("backhalf", backhalf);
        data.append("title", Title);
        data.append("created_by", created_by);
        data.append("owner_id", brandsOwner_id);
        data.append("brand_id", brandId);        
        data.append("long_url", full_short_url);
        data.append("user_query", user_query);

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        setSaveFlexpage(true)
        axios.post(url, data, { headers })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status == true) {

                        webBuilderDispatch({ type: 'CHANGE_GENERATION_STATUS', payload: { status: true } });

                        generateOutlines(userQuery)

                        history.push("/webpage/" + uniqueId);

                    }
                    else {
                        PNotify.error({
                            title: 'Error',
                            text: res.data.message,
                        })
                    }
                }
                else {

                    PNotify.error({
                        title: 'Error',
                        text: res.data.message,
                    })
                }
            })
            .catch(error => {
                console.error('error', error);
                PNotify.error({
                    title: 'Error',
                    text: error.message,
                })
            }).finally(() => {
                setSaveFlexpage(false)
            });
    }
    useEffect(() => {
        getWebPageDta();
    }, [])
    useEffect(() => {
        getBrandData()
    }, [])
    const generateUniqueId = () => {
        const id = nanoid(8);
        setUniqueId(id);
    };
    useEffect(() => {

        handleSelectBrand()

    }, [BrandId, selectTemp, brandsOwner_id])
    const filteredCampaignData = pageData.filter((item) => item?.title?.toLowerCase()?.includes(campaignSearch?.toLowerCase()));
    return (

        <>
            {/* {loadingcomponent && <Loader2 />} */}

            <>
                <Modal show={show01} onHide={handleClose01} centered className="mini-page-modal Sidebar-Modal-end">
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><MdPages />Create Page</Modal.Title>
                        {/* <Modal.Title>Create New SmartLink</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <div className='col-lg-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicTemplate">
                                <input type="text" value={title} className="did-floating-input form-control" placeholder=""
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Form.Label className="did-floating-label">Page Title</Form.Label>
                            </Form.Group>
                        </div>



                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Link to="/minipage"> */}
                        <button className="Active-bttn btn" onClick={() => {
                            // setDuplicateData({
                            //     title: title,
                            //     uniqueId: uniqueId,
                            //     activeIndex: activeIndex,
                            //     brand_id: BrandId,
                            //     page_json: page_json,
                            //     page_thumbnail: page_thumbnail,
                            //     domain_name: domain_name,
                            //     background_color: background_colors,
                            //     button_color: button_colors,
                            //     font_family: font_familys,
                            // })
                            // handleShow04()
                            // switchToModal04()
                        }} > Create duplicate FlexPage </button>
                        {/* </Link> */}
                    </Modal.Footer>
                </Modal>
                {/* mini page modal start here  */}


                <Modal show={show02} onHide={handleClose02} centered className='Confirm-Delete'>
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><TbTrash />Subscription not active !</Modal.Title>
                    </Modal.Header>
                    {role == "0" ? (<>
                        <Modal.Body>Please Upgrade to  an active plan to continue using this feature</Modal.Body>
                        <Modal.Footer>
                            <Button className='delete-Cancel-bttn' onClick={() => { history.push("/setting/price") }}>
                                Upgrade
                            </Button>
                        </Modal.Footer>
                    </>) : (
                        <Modal.Body>Please contact ypur admin and ask them to upgrade to  an active plan.</Modal.Body>
                    )}
                </Modal>




                <Modal show={show03} onHide={handleClose03} centered className='Confirm-Delete'>
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><TbTrash />Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                    <Modal.Footer>
                        <Button className='delete-Cancel-bttn' onClick={handleClose03}>
                            Cancel
                        </Button>
                        <Button className='confirm-Delete-bttn' onClick={() => {
                            handleClose03();
                            // deleteuserflexpage();
                        }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show04} onHide={handleClose04} centered className="mini-page-modal Sidebar-Modal-end">
                    <Modal.Header closeButton>
                        <Modal.Title className="delete-modal-title"><MdPages />Create WebPage</Modal.Title>
                        {/* <Modal.Title>Create New SmartLink</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <div className='col-lg-12'>
                            <Form.Group className="did-floating-label-content" controlId="formBasicTemplate">
                                <input type="text" value={title} className="did-floating-input form-control" placeholder=""
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Form.Label className="did-floating-label">Page Title</Form.Label>
                            </Form.Group>
                        </div>

                        <div className="col-md-12">
                            <Form.Group className="did-floating-label-content" controlId="formBasic">
                                <Form.Select className="did-floating-select" aria-label="Default select example" onChange={
                                    (e) => {
                                        const value = e.target.value;
                                        const selectedOpt = e.target.templateData;
                                        setBrandId(value);
                                        const selectedOption = e.target.selectedOptions[0];
                                        const Brandsowner_id = selectedOption.getAttribute('Brandsowner_id');
                                        setBrandsOwner_id(Brandsowner_id);

                                    }
                                }>

                                    {brandData && brandData.map((item, index) => {
                                        return (
                                            <option key={index} selected={BrandId === item.brand_id} Brandsowner_id={item.owner_id} value={item.brand_id} templateData={JSON.stringify(item.template)} domainData={JSON.stringify(item.domain)}>{item.brand_name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label className="did-floating-label">Brand's Name</Form.Label>
                            </Form.Group>
                        </div>

                        <div className='col-lg-12'>
                            {/* <label id="input-field--label" class="input-field--label">Update Vanity Tag</label> */}
                            <div className="input-field--CUSTOM2 error">
                                <div className="d-flex align-items-center justify-content-around">
                                    <Form.Select aria-label="Default select example" className="custom-drop-downbtn"
                                        onChange={handleDomainSelect}
                                    >

                                        {domainData.map((item, index) => {

                                            return (
                                                <option key={index} value={item.domain_name}>{item.domain_name}</option>
                                            )
                                        })
                                        }
                                    </Form.Select>

                                    <div className="input-field--input-wrapper">
                                        <input aria-labelledby="input-field--label" type="text" class="input-field--input2" value={uniqueId}
                                            onChange={(e) => setUniqueId(e.target.value)}
                                            onFocus={handleVerifyBackhalf}
                                            onBlur={handleVerifyBackhalf}
                                        />
                                    </div>
                                    <div className="input-field--static-value">
                                        {backhalfVerfied ? <div className="icon-check" style={{ color: "green" }}>
                                            <BsCheckCircleFill />
                                        </div> : <div className="icon-cross" style={{ color: "red" }}>
                                            <BsXCircleFill />
                                        </div>}

                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-12'>
                                <Form.Group className="did-floating-label-content" controlId="formBasicTemplate">
                                    <input type="text" value={userQuery} className="did-floating-input form-control" placeholder=""
                                        onChange={(e) => setUserQuery(e.target.value)}
                                    />
                                    <Form.Label className="did-floating-label">Web page Prompt</Form.Label>
                                </Form.Group>
                            </div>
                        </div>




                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Link to="/minipage"> */}
                        <button

                            className="Active-bttn btn" onClick={() => {
                                saveFlexData("new", {});
                                // handleClose04()
                                // generateOutlines(userQuery);
                            }} >{saveflexpage             ? <Spinner /> : " Create WebPage"} </button>

                        {/* </Link> */}
                    </Modal.Footer>
                </Modal>
                <Header />

                <div className="main-container">
                    <div className="main-page-body-content">
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="container-fulid">
                                    <div className="row pd-26">
                                        <div className="ps-0 pe-0">

                                            <div className="card-body table-card-body">
                                                <div className="table-card-header add-btn d-flex">
                                                    <h5 className="main-card-title"><MdPages className="header-icon" />WEB PAGES</h5>
                                                    <div className="card-content-header-right">
                                                        <Button className="btn btn-sm float-right add-user-btn" onClick={() => {

                                                            if ((subscriptionStatus == "incomplete" || subscriptionStatus == "incomplete_expired" || subscriptionStatus == "past_due"
                                                                || subscriptionStatus == "canceled" || subscriptionStatus == "unpaid" || subscriptionStatus == "paused")) {
                                                                // handleShow06();
                                                            } else {

                                                                handleShow04();
                                                                generateUniqueId();
                                                            }

                                                        }}> <MdPages /> New WebPages</Button>
                                                    </div>
                                                </div>
                                                <div className="wrap-campion">
                                                    <form role="search" className="search-form form">
                                                        <label>
                                                            <span className="screen-reader-text">Search for...</span>
                                                            <input type="search" className="search-field" placeholder="Search..." value={campaignSearch} onChange={handleCampaignSearch} />
                                                        </label>
                                                        <button class="search-submit button">
                                                            <FaSearch />
                                                        </button>
                                                    </form>
                                                    <div className="XZYPL">

                                                        <select className="event-golle" name="event-golle" value={selectedFilter} onChange={handleFilterChange}>
                                                            <option value="All">All</option>
                                                            <option value="Active">Active</option>
                                                            <option value="Scheduled">Scheduled</option>
                                                            <option value="Completed">Completed</option>
                                                        </select>
                                                        <div className="clear-btn">
                                                            <button className="close-btn-cmp" onClick={handleClearFilter}>
                                                                <RiCloseFill />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mini-page-list">
                                                    <div className="mini-page-list-inner row">
                                                        {pageData && pageData.map((item, index) => {
                                                            let objectData = {}

                                                            if (item.type == 'HERO') {
                                                                objectData = item.components
                                                            }
                                                            console.log("filteredCampaignData", objectData);

                                                            return (
                                                                <div key={index} className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6'>
                                                                    <div className="mini-page-list-inner-item">
                                                                        <div className="mini-page-list-inner-item-img">
                                                                            <img
                                                                                src={API_URL + "flextemplates/" + item.page_thumbnail}
                                                                                alt="Image preview not available"
                                                                                onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/d/dc/No_Preview_image_2.png'}
                                                                            />
                                                                        </div>
                                                                        <div className="mini-page-list-inner-item-content">
                                                                            <div className="mini-page-list-inner-item-content-title">
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                    <h5 className="page-title">

                                                                                        <div className='edit-title' onClick={() => {
                                                                                            setEditableTitle(item.title);
                                                                                            setIndex(index);
                                                                                            setFlex_id(item._id);
                                                                                            handleShow02();
                                                                                        }}>
                                                                                            <MdEdit />
                                                                                        </div>if you have a long title, it will be truncated

                                                                                    </h5>
                                                                                    <div className="campaign-table-image" style={{ marginTop: -5 }}>
                                                                                        {/* {filteredCampaignData.map((campaign, index) => ( */}
                                                                                        <div key={index} className="campaign-table-row">
                                                                                            <div className="campaign-table-image" style={{ marginTop: -5 }}>
                                                                                                {item.icon}
                                                                                            </div>
                                                                                            <div className="campaign-table-details">

                                                                                            </div>
                                                                                        </div>
                                                                                        {/* ))} */}

                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex align-items-center gap-2'>
                                                                                    <p>https://hatakeconstruction.withastral.com/QkUdiqpw</p>
                                                                                    <CopyCat text="https://hatakeconstruction.withastral.com/QkUdiqpw" />
                                                                                    {/* <SocialSharePage
                                                                                        url={modifiedUrl}
                                                                                        title="Check out this Page"
                                                                                        description="I found this great website and thought you might like it too."
                                                                                    /> */}
                                                                                </div>
                                                                            </div>

                                                                            <div className='inline-box'>
                                                                                <div className="SmartLinks-table-view">
                                                                                    <div className="views04">
                                                                                        <h6>46 <span> Visits</span></h6>
                                                                                        <h6>12 Clicks</h6>
                                                                                        <h6>34 Scans</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='mini-page-list-inner-item-content-btn'>
                                                                                    <div className="d-flex new-M">
                                                                                        <button aria-label="Duplicate" tooltip-position="top" type='button' className="btn duplicate-btn me-3"
                                                                                        // onClick={() => {
                                                                                        //     setPage_json(item.page_json);
                                                                                        //     setPage_thumbnail(item.page_thumbnail);
                                                                                        //     setDomain_name(item.domain_name);
                                                                                        //     setTitle(item.title);
                                                                                        //     setBackground_color(item.background_color);
                                                                                        //     setButton_color(item.button_color);
                                                                                        //     setFont_family(item.font_family);
                                                                                        //     setBrandId(item.brand_id);
                                                                                        //     handleShow01();
                                                                                        //     generateUniqueId();
                                                                                        // }}
                                                                                        >
                                                                                            <BiDuplicate />
                                                                                        </button>
                                                                                        <button aria-label='Qr Code' tooltip-position="top" type="button" className="btn btn-layout me-3"
                                                                                        // onClick={() => { handleShow05(API_URL + "qrcodes/" + item.template_image) }}
                                                                                        >
                                                                                            <MdQrCode />
                                                                                        </button>
                                                                                        <button aria-label='Metrics' tooltip-position="top" type="button" className="btn btn-chart me-3">
                                                                                            <Link
                                                                                            // to={`/flexpage/metrics/` + item.backhalf}
                                                                                            >
                                                                                                <TbChartBar />
                                                                                            </Link>
                                                                                        </button>
                                                                                        <button aria-label="Edit" tooltip-position="top" type="button" className="btn btn-edit me-3"
                                                                                        >
                                                                                            <Link
                                                                                            // to={`/editEvent/${item._id}/eventinfo`}
                                                                                            >
                                                                                                <TbEdit />

                                                                                            </Link>
                                                                                        </button>
                                                                                        <button aria-label='Delete' tooltip-position="top" type="button" className="btn btn-delete"
                                                                                        // onClick={() => { handleShow03(item._id, index) }}
                                                                                        >
                                                                                            <TbTrash />
                                                                                        </button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>

        </>
    );
}

export default WebPagesList;