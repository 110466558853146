import { useState, useEffect, useRef, useContext } from 'react';
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';

import {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  List,
  Link,
  Strikethrough,
  Code,
  Palette,
  Type,
  TextSize
} from 'lucide-react';

const TextEditor = ({ blockId, content }) => {
  const { webBuilderDispatch } = useContext(WebBuilderContext);
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const editorRef = useRef(null);
  const toolbarRef = useRef(null);
  const [text, setText] = useState('<p>Enter text </p>');

  const colors = [
    '#000000', '#434343', '#666666', '#999999', '#b7b7b7', '#cccccc', 
    '#d9d9d9', '#efefef', '#f3f3f3', '#ffffff', '#980000', '#ff0000', 
    '#ff9900', '#ffff00', '#00ff00', '#00ffff', '#4a86e8', '#0000ff', 
    '#9900ff', '#ff00ff'
  ];

  const fonts = [
    { label: 'Default', value: 'inherit' },
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Times New Roman', value: 'Times New Roman, serif' },
    { label: 'Helvetica', value: 'Helvetica, sans-serif' },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Verdana', value: 'Verdana, sans-serif' },
    { label: 'Courier New', value: 'Courier New, monospace' }
  ];

  const fontSizes = [
    { label: 'Default', value: '16px' },
    { label: 'Small', value: '12px' },
    { label: 'Medium', value: '16px' },
    { label: 'Large', value: '20px' },
    { label: 'X-Large', value: '24px' },
    { label: '2X-Large', value: '32px' }
  ];

  const tools = [
    {
      label: 'Normal text',
      options: ['Normal text 1', 'Normal text', 'Heading 1', 'Heading 2', 'Heading 3']
    },
    { icon: Bold, command: 'bold' },
    { icon: Italic, command: 'italic' },
    { icon: Underline, command: 'underline' },
    { icon: Strikethrough, command: 'strikethrough' },
    { icon: Code, command: 'code' },
    { icon: Link, command: 'createLink' },
    { icon: Palette, command: 'color' },
    { icon: AlignLeft, command: 'justifyLeft' },
    { icon: AlignCenter, command: 'justifyCenter' },
    { icon: AlignRight, command: 'justifyRight' },
    { icon: List, command: 'insertUnorderedList' }
  ];

  const updateContent = async (newText) => {
    webBuilderDispatch({ type: 'UPDATE_BLOCK_TEXT', payload: { blockId, itemId: content.id, newText } });
  }

  useEffect(() => {
    setText(content.data.text);
  }, []);

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      const editorContainsSelection =
        editorRef.current?.contains(selection.anchorNode);

      if (selection.toString().length > 0 && editorContainsSelection) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        const toolbarHeight = toolbarRef.current?.offsetHeight || 0;
        const toolbarWidth = toolbarRef.current?.offsetWidth || 0;

        const topPosition =
          rect.top - toolbarHeight - 10 > 0
            ? rect.top - toolbarHeight - 10
            : rect.bottom + 10;

        const leftPosition = Math.min(
          rect.left + rect.width / 2 - toolbarWidth / 2,
          window.innerWidth - toolbarWidth
        );

        setToolbarPosition((prev) => {
          if (prev.top !== topPosition || prev.left !== leftPosition) {
            return { top: topPosition, left: leftPosition };
          }
          return prev;
        });

        if (!showToolbar) {
          setShowToolbar(true);
        }
      } else if (showToolbar) {
        setShowToolbar(false);
      }
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [showToolbar]);

  const handleFormat = (command) => {
    if (command === 'createLink') {
      const url = prompt('Enter URL:');
      if (url) document.execCommand(command, false, url);
    } else if (command === 'color') {
      setShowColorPicker(!showColorPicker);
    } else {
      document.execCommand(command, false, null);
    }

    // if (onChange && editorRef.current) {
    //   onChange(editorRef.current.innerHTML);
    // }
  };

  const handleColorSelect = (color) => {
    document.execCommand('foreColor', false, color);
    setShowColorPicker(false);
    if (editorRef.current) {
      updateContent(editorRef.current.innerHTML);
    }
  };

  const handleFontChange = (fontFamily) => {
    document.execCommand('fontName', false, fontFamily);
    if (editorRef.current) {
      updateContent(editorRef.current.innerHTML);
    }
  };

  const handleFontSizeChange = (fontSize) => {
    // Convert pixel value to a relative size (1-7)
    const size = Math.ceil(parseInt(fontSize) / 4);
    document.execCommand('fontSize', false, size);
    if (editorRef.current) {
      updateContent(editorRef.current.innerHTML);
    }
  };

  const handleTextTypeChange = (event) => {
    const format = event.target.value;
    let command = 'p';

    if (format.startsWith('Heading')) {
      const level = format.split(' ')[1];
      command = `h${level}`;
    }

    document.execCommand('formatBlock', false, `<${command}>`);
  };

  return (
    <div
      className="position-relative w-100"
      onClick={() => {
        webBuilderDispatch({ type: 'UPDATE_CONTENT_ID', payload: { contentId: content.id } });
      }}
    >
      {showToolbar && (
        <div
          ref={toolbarRef}
          className="position-fixed bg-white rounded shadow-sm d-flex align-items-center p-1 gap-2"
          style={{
            top: `${toolbarPosition.top}px`,
            left: `${toolbarPosition.left}px`,
            zIndex: 1050
          }}
        >
          {/* Text type dropdown */}
          <select
            onChange={handleTextTypeChange}
            className="form-select form-select-sm border-0"
            style={{ width: 'auto', minWidth: '120px' }}
          >
            {tools[0].options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          {/* Font family dropdown */}
          <select
            onChange={(e) => handleFontChange(e.target.value)}
            className="form-select form-select-sm border-0"
            style={{ width: 'auto', minWidth: '120px' }}
          >
            {fonts.map((font) => (
              <option key={font.value} value={font.value}>
                {font.label}
              </option>
            ))}
          </select>

          {/* Font size dropdown */}
          <select
            onChange={(e) => handleFontSizeChange(e.target.value)}
            className="form-select form-select-sm border-0"
            style={{ width: 'auto', minWidth: '100px' }}
          >
            {fontSizes.map((size) => (
              <option key={size.value} value={size.value}>
                {size.label}
              </option>
            ))}
          </select>

          <div className="vr"></div>

          <div className="btn-group btn-group-sm position-relative">
            {tools.slice(1).map((tool, index) => {
              const Icon = tool.icon;
              return (
                <button
                  key={index}
                  onClick={() => handleFormat(tool.command)}
                  className="btn btn-light border-0"
                  title={tool.command}
                >
                  <Icon size={16} />
                </button>
              );
            })}
            
            {showColorPicker && (
              <div 
                className="position-absolute bg-white rounded shadow-sm p-2"
                style={{
                  top: '100%',
                  left: '0',
                  width: '200px',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(10, 1fr)',
                  gap: '2px',
                  zIndex: 1060
                }}
              >
                {colors.map((color) => (
                  <button
                    key={color}
                    onClick={() => handleColorSelect(color)}
                    className="w-4 h-4 rounded border border-gray-200"
                    style={{ backgroundColor: color }}
                    title={color}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="position-relative">
        <div
          ref={editorRef}
          contentEditable
          className="form-control border-0 shadow-none h-auto bg-transparent p-3"
          onInput={(e) => {
            updateContent(e.currentTarget.innerHTML);
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

export default TextEditor;