import React, { useState, useEffect, useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Link, useLocation, useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { brandSelectionContext } from "../../contexts/context/brandSelectionContext";
import { useIntercom } from 'react-use-intercom';
import { API_URL } from "../../BackendApi/Api/config";
import { logout } from "../../BackendApi/Api/auth";
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";
import { AiOutlineSetting, AiOutlineClose } from "react-icons/ai";
import {
  MdBugReport, MdEvent, MdPages
} from "react-icons/md";
import {
  TbSmartHome,
  TbComponents,
  TbLogout,
  TbBulb,
  TbSocial,
  TbAuth2Fa,
  TbAi,
} from "react-icons/tb";
import { BsPalette, BsMegaphone } from "react-icons/bs";
import { BiSolidBell } from "react-icons/bi";
import astral from "../../assets/images/logo/logo.png";
import { Dropdown } from "react-bootstrap";

import Icon01 from "../../assets/images/indent-decrease.svg";
import Icon02 from "../../assets/images/indent-increase.svg";
import UnableUser from "../UnableUser";
import useTagsActions from "../../contexts/actions/useTagsActions";
const Sidebar = () => {
  const history = useHistory();
  const { sidebarBrands, sidebarBrandsDispatch } = useContext(brandSelectionContext);
  const { boot } = useIntercom();
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('theme') === 'dark');
  const userData = JSON.parse(localStorage.getItem("user"));

  const { getAllTags } = useTagsActions();

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    if (userData) {
      boot({
        app_id: process.env.REACT_APP_INTERCOM_ID,
        name: `${userData.fname} ${userData.lname}`,
        email: userData.email,
        created_at: userData.joindate,
      });
    }
  }, [userData, boot]);

  useEffect(() => {
    if (!window.Canny) {
      (function (w, d, i, s) {
        function l() {
          if (!d.getElementById(i)) {
            var f = d.getElementsByTagName(s)[0];
            var e = d.createElement(s);
            e.type = 'text/javascript';
            e.async = true;
            e.src = 'https://canny.io/sdk.js';
            f.parentNode.insertBefore(e, f);
            e.onload = () => {
              window.Canny('initChangelog', {
                appID: '650afb424a24c6093c6d5139',
                position: 'bottom',
                align: 'left',
                theme: 'light',
              });
            };
          }
        }
        if (typeof w.Canny !== 'function') {
          var c = function () {
            c.q.push(arguments);
          };
          c.q = [];
          w.Canny = c;
          if (d.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })(window, document, 'canny-jssdk', 'script');
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle(isDarkMode ? 'dark' : 'light', true);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode(prev => !prev);

  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    document.querySelector(".main-container").classList.toggle("new-class-name");
    setCollapse(!collapse);
  };

  const addClass = () => collapse
    ? document.querySelector(".sidebar-container").classList.add("class-none")
    : document.querySelector(".sidebar-container").classList.remove("new-class-name");

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [menuCollapse, setMenuCollapse] = useState(false);
  const menuIconClick = () => setMenuCollapse(prev => !prev);

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const proimg = `${API_URL}profile/${currentUser.profile_image}`;

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <img src={props.data.icon} alt="logo" className="brand-logo" />
      <p className="brand-name">{props.data.label}</p>
    </components.Option>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={props.data.icon} alt="logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const brandOptions = [
    {
      _id: "all",
      company_name: "All Brands",
      logo_light: "https://imageio.forbes.com/specials-images/imageserve/648785bcbaf2e13bf58d81e4/Brands--Marketing--Brand-names--Lexicon--Novon/960x0.jpg?format=jpg&width=960"
    },
    ...sidebarBrands.brands].map(brand => ({
      value: brand._id,
      label: brand.company_name,
      icon: brand.logo_light.startsWith("https://") ? brand.logo_light : `${API_URL}brands/${brand.logo_light}`
    }));

  const selectedBrand = brandOptions.find(option => option.value === sidebarBrands.selected) || brandOptions[0];

  // const brandOptions = () => {

  //   const brandoptions = sidebarBrands.brands.map(brand => ({
  //     value: brand._id,
  //     label: brand.company_name,
  //     icon: brand.logo_light.startsWith("https://") ? brand.logo_light : `${API_URL}brands/${brand.logo_light}`
  //   }));

  //   return [
  //     {
  //       value: "all",
  //       label: "All Brands",
  //       icon: ""
  //     },
  //     ...brandoptions
  //   ]


  // }

  const handleBrandChange = (selectedOption) => {
    const confirmChange = window.confirm("Are you sure you want to change the selected brand?");
    if (confirmChange) {
      sidebarBrandsDispatch({ type: "SET_SELECTED_BRAND", payload: selectedOption.value });
      localStorage.setItem('selectedbrand', selectedOption.value);
      history.push('/dashboard');
      // window.location.reload();
    }
  };

  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              {menuCollapse ? (
                <img src={require('../../assets/images/logo/Popuplogo.png')} alt="logo" className="logo02" />
              ) : (
                <img src={astral} alt="logo" className="logo01" />
              )}
            </div>
            <div onClick={toggle}>
              <div className="closemenu" onClick={menuIconClick}>
                {menuCollapse ? (
                  <img src={Icon02} alt="icn" />
                ) : (
                  <img src={Icon01} alt="icn" />
                )}
              </div>
            </div>
            <div className="closemenu-mobile" onClick={addClass}>
              <AiOutlineClose />
            </div>
          </SidebarHeader>
          {(userData.role == "0" || userData.role == "2") &&
            <>
              <SidebarContent>
                <Menu iconShape="square">
                  <Link to="/dashboard" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "dashboard" ? "active" : ""}
                      icon={ <TbSmartHome  />}
                    >
                      Dashboard
                    </MenuItem>
                  </Link>
                  <Link to="/flexlinks" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "flexlinks" || splitLocation[1] === "createflexlink" ? "active" : ""}
                      icon={<TbComponents />}
                    >
                      FlexLinks
                    </MenuItem>
                  </Link>
                  <Link to="/flexpage" onClick={addClass}>
                    <MenuItem className={splitLocation[1] === "flexpage" || splitLocation[1] === "minipage" ? "active" : ""} icon={<MdPages />}>
                      FlexPages
                    </MenuItem>
                  </Link>
                  <Link to="/events-creation" onClick={addClass}>
                  <MenuItem className={splitLocation[1] === "events" || splitLocation[1] === "minipage" ? "active" : ""} icon={<MdEvent />}>
                      Events
                    </MenuItem>
                  </Link>
                  <Link to="/social_design/calendar" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "social_design" ? "active" : ""}
                      icon={<TbSocial />}
                    >
                      Social Content
                    </MenuItem>
                  </Link>
                  <Link to="/webpages" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "webpages" ? "active" : ""}
                      icon={<TbSocial />}
                    >
                      Web Builder
                    </MenuItem>
                  </Link>
                  <Link to="/campaigns" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "campaigns" || splitLocation[1] === "viewcampaign" ? "active" : ""}
                      icon={<BsMegaphone />}
                    >
                      Campaigns
                    </MenuItem>
                  </Link>
                  <Link to="/custombrand" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "custombrand" || splitLocation[1] === "brandedit" ? "active" : ""}
                      icon={<BsPalette />}
                    >
                      Brand Kit
                    </MenuItem>
                  </Link>
                  <Link to="/feature-request" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "feature-request" ? "active" : ""}
                      icon={<TbBulb />}
                    >
                      Request Features
                    </MenuItem>
                  </Link>
                  <Link to="/bugs-report" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "bugs-report" ? "active" : ""}
                      icon={<MdBugReport />}
                    >
                      Report Bugs
                    </MenuItem>
                  </Link>
                  <Select
                    // menuIsOpen={true}
                    className="brand-select mt-3"
                    value={selectedBrand}
                    options={brandOptions}
                    onChange={handleBrandChange}
                    components={{ Option, SingleValue }}
                    styles={{
                      singleValue: (base) => ({
                        ...base,
                        display: "flex",
                        alignItems: "center",
                      }),
                    }}
                  />
                </Menu>
              </SidebarContent>
              <SidebarFooter>
                <Menu iconShape="square">
                  <div className="sidebar-footer d-flex align-items-center">
                    <Dropdown className="sidebar-custom-drop">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="sidebar-custom-drop-btn"
                      >
                        <img
                          src={proimg}
                          className="rounded-circle"
                          alt="Cinque Terre"
                          width="50"
                          height="50"
                        />
                        <p className="mb-0">{currentUser.fname}</p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="sidebar-custom-drop-item">
                          <button
                            className="sidebar-btn-drop"
                            onClick={() => {
                              addClass();
                              logout();
                            }}
                          >
                            <TbLogout />
                            Logout
                          </button>
                        </Dropdown.Item>
                        <Dropdown.Item className="sidebar-custom-drop-item">
                          <Link to="/setting" onClick={addClass}>
                            <AiOutlineSetting />
                            Settings
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button data-canny-changelog className="sidebar-footer-notify">
                      <BiSolidBell className="sidebar-footer-notify-icon" />
                    </button>
                  </div>
                </Menu>
              </SidebarFooter></>}
          {
            userData.role == "1" &&
            <>
               <SidebarContent>
                <Menu iconShape="square">
                  <Link to="/admindashboard" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "admindashboard" ? "active" : ""}
                      icon={<TbSmartHome />}
                    >
                      Users
                    </MenuItem>
                  </Link>
                  <Link to="/brands" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "brands" ? "active" : ""}
                      icon={<BsPalette />}
                    >
                      Brands
                    </MenuItem>
                  </Link>
                  
                  <Link to="/Vanity" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "Vanity" ? "active" : ""}
                      icon={<TbSmartHome />}
                    >
                      Vanity
                    </MenuItem>
                  </Link>
                  <Link to="/AiPreamble" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "AiPreamble" ? "active" : ""}
                      icon={<TbAi />}
                    >
                      AiPreamble
                    </MenuItem>
                  </Link>
                  <Link to="/event-categories" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "event-categories" ? "active" : ""}
                      icon={<TbAi />}
                    >
                      Event Categories
                    </MenuItem>
                  </Link>
                  <Link to="/event-cities" onClick={addClass}>
                    <MenuItem
                      className={splitLocation[1] === "event-cities" ? "active" : ""}
                      icon={<TbAi />}
                    >
                      Event Cities
                    </MenuItem>
                  </Link>
                  
                 
                </Menu>
              </SidebarContent>
              <SidebarFooter>
                <Menu iconShape="square">
                  <div className="sidebar-footer d-flex align-items-center">
                    <Dropdown className="sidebar-custom-drop">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="sidebar-custom-drop-btn"
                      >
                        <img
                          src={proimg}
                          className="rounded-circle"
                          alt="Cinque Terre"
                          width="50"
                          height="50"
                        />
                        <p className="mb-0">{currentUser.fname}</p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="sidebar-custom-drop-item">
                          <button
                            className="sidebar-btn-drop"
                            onClick={() => {
                              addClass();
                              logout();
                            }}
                          >
                            <TbLogout />
                            Logout
                          </button>
                        </Dropdown.Item>
                        {/* <Dropdown.Item className="sidebar-custom-drop-item">
                          <Link to="/setting" onClick={addClass}>
                            <AiOutlineSetting />
                            Settings
                          </Link>
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    <button data-canny-changelog className="sidebar-footer-notify">
                      <BiSolidBell className="sidebar-footer-notify-icon" />
                    </button>
                  </div>
                </Menu>
              </SidebarFooter>
              </>

          }
        </ProSidebar>
      </div>
    </>
  );
};

export default Sidebar;
