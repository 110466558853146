import React, { useState, useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';

const SingleVideoEditor = ({ blockId, content }) => {
    const { webBuilderDispatch } = useContext(WebBuilderContext);
    const [video, setVideo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [title, setTitle] = useState("");
    const [dimensions, setDimensions] = useState({ width: 560, height: 315 });
    const [isResizing, setIsResizing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const videoRef = useRef(null);
    const startResizePos = useRef({ x: 0, y: 0 });
    const startDimensions = useRef({ width: 0, height: 0 });

    const extractVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    const validateAndSetVideo = (url) => {
        const videoId = extractVideoId(url);
        if (videoId) {
            setVideo({
                id: videoId,
                url: `https://www.youtube.com/embed/${videoId}`,
                title: title || "YouTube Video"
            });
            return true;
        } else {
            alert("Please enter a valid YouTube URL");
            return false;
        }
    };

    const handleStartResize = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsResizing(true);
        startResizePos.current = { x: e.clientX, y: e.clientY };
        startDimensions.current = { ...dimensions };
    };

    const handleResize = (e) => {
        if (!isResizing) return;

        const deltaX = e.clientX - startResizePos.current.x;
        const deltaY = e.clientY - startResizePos.current.y;

        setDimensions({
            width: Math.max(280, startDimensions.current.width + deltaX),
            height: Math.max(157, startDimensions.current.height + deltaY)
        });
    };

    const handleStopResize = () => {
        if (isResizing) {
            handleSave();
            setIsResizing(false);
        }
    };

    useEffect(() => {
        if (content.video) {
            setVideo(content.video);
            setVideoUrl(`https://youtube.com/watch?v=${content.video.id}`);
            setTitle(content.video.title || "");
            setDimensions(content.dimensions || { width: 560, height: 315 });
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousemove', handleResize);
        document.addEventListener('mouseup', handleStopResize);

        return () => {
            document.removeEventListener('mousemove', handleResize);
            document.removeEventListener('mouseup', handleStopResize);
        };
    }, [isResizing]);

    const handleSave = () => {
        if (video) {
            webBuilderDispatch({
                type: 'UPDATE_YOUTUBE_VIDEO',
                payload: {
                    blockId,
                    contentId: content.id,
                    video: { ...video, title },
                    dimensions
                }
            });
        }
        setIsEditing(false);
    };

    const closeEditor = () => {
        setIsEditing(false);
    };

    return (
        <div
            className="container py-4"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                webBuilderDispatch({
                    type: 'UPDATE_CONTENT_ID',
                    payload: { contentId: content.id }
                });
            }}
        >
            <div className="text-center mb-4 position-relative">
                {video && (
                    <div
                        className="d-inline-block position-relative"
                        style={{
                            width: dimensions.width,
                            height: dimensions.height,
                        }}
                    >
                        <iframe
                            ref={videoRef}
                            src={video.url}
                            title={video.title}
                            style={{
                                width: "100%",
                                height: "100%",
                                border: 0,
                                borderRadius: "8px",
                            }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        {isHovered && (
                            <>
                                <div
                                    className="position-absolute"
                                    style={{
                                        right: -8,
                                        bottom: -8,
                                        width: 16,
                                        height: 16,
                                        backgroundColor: '#007bff',
                                        cursor: 'nwse-resize',
                                        borderRadius: '50%',
                                        zIndex: 1
                                    }}
                                    onMouseDown={handleStartResize}
                                />
                                <div
                                    className="position-absolute"
                                    style={{
                                        top: 8,
                                        right: 8,
                                        zIndex: 1
                                    }}
                                >
                                    <button
                                        className="btn btn-light btn-sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsEditing(true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                )}

                {isHovered && !video && (
                    <button
                        className="btn btn-primary"
                        onClick={() => setIsEditing(true)}
                    >
                        Add YouTube Video
                    </button>
                )}
            </div>

            {isEditing && ReactDOM.createPortal(
                <div
                    className="modal show fade d-block"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {video ? "Edit Video" : "Add YouTube Video"}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={closeEditor}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                {video && (
                                    <div className="mb-4 text-center">
                                        <iframe
                                            src={video.url}
                                            title={video.title}
                                            style={{
                                                width: "100%",
                                                height: "200px",
                                                border: 0,
                                                borderRadius: "8px"
                                            }}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div>
                                )}

                                <div className="mb-3">
                                    <label className="form-label">YouTube URL</label>
                                    <input
                                        type="text"
                                        value={videoUrl}
                                        onChange={(e) => setVideoUrl(e.target.value)}
                                        placeholder="https://www.youtube.com/watch?v=..."
                                        className="form-control"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="form-control"
                                        placeholder="Video title for accessibility"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={closeEditor}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        if (validateAndSetVideo(videoUrl)) {
                                            handleSave();
                                        }
                                    }}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default SingleVideoEditor;