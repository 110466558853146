import React, { useState } from 'react';
import useWebBuilderActions from '../../../../contexts/actions/useWebBuilderActions';
import {
    Layout,
    Type,
    Image,
    Plus,
    Film,
    BarChart2,
    PenTool,
    Heading1,
    Heading2,
    Heading3,
    Quote,
    Table,
    List,
    ListOrdered,
    CheckSquare,
    AlertCircle,
    Info,
    Mouse,
    Star,
    Youtube
} from 'lucide-react';
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';

const SidebarMenu = () => {

    const { webBuilderDispatch } = React.useContext(WebBuilderContext);
    const { addContent } = useWebBuilderActions();
    const [activeMenu, setActiveMenu] = useState(null);

    const formatOptions = {
        'Text': [
            { icon: Type, label: 'Text', shortcut: 'T', identity: 'NORMAL_TEXT' },
            { icon: Heading1, label: 'Heading 1', shortcut: 'H1', identity: 'HEADING_1' },
            { icon: Heading2, label: 'Heading 2', shortcut: 'H2', identity: 'HEADING_2' },
            { icon: Heading3, label: 'Heading 3', shortcut: 'H3', identity: 'HEADING_3' },

            { icon: Mouse, label: 'Button', shortcut: 'B', identity: 'BUTTON' },
            { icon: Star, label: 'Testimonials', shortcut: 'Q', identity: 'TESTIMONIALS' },
        ],
        'Tables': [
            { icon: Table, label: '2×2 table' },
            { icon: Table, label: '3×3 table' },
            { icon: Table, label: '4×4 table' }
        ],
        'Lists': [
            { icon: List, label: 'Bulleted list' },
            { icon: ListOrdered, label: 'Numbered list' },
            { icon: CheckSquare, label: 'Todo list' }
        ],
        'Callouts': [
            { icon: Info, label: 'Note box' },
            { icon: AlertCircle, label: 'Info box' },
            { icon: AlertCircle, label: 'Warning box' }
        ],
        'Image': [
            { icon: Image, label: 'Image Grid', shortcut: 'G', identity: 'IMAGE_GRID' },
            { icon: Image, label: 'Image ', shortcut: 'S', identity: 'SINGLE_IMAGE' },
        ],
        'Media': [
            { icon: Youtube, label: 'Youtube', shortcut: 'Y', identity: 'YOUTUBE_VIDEO' },
        ]
    };

    const menuItems = [
        { icon: Plus, label: 'Add Block' },
        { icon: Layout, label: 'Layout' },
        { icon: Type, label: 'Text' },
        { icon: Image, label: 'Image' },
        { icon: Film, label: 'Media' },
        { icon: BarChart2, label: 'Chart' },
        { icon: PenTool, label: 'Draw' }
    ];

    const addElement = (element) => {

        console.log('the function has triggered')

        addContent(element)
    }

    return (
        <div className="position-fixed" style={{
            right: '1rem',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1030
        }}>
            <div className="d-flex position-relative">
                {activeMenu && (
                    <div className="position-absolute bg-dark rounded p-3"
                        style={{
                            right: '100%',
                            marginRight: '0.5rem',
                            width: '380px'
                        }}>
                        <div className="text-white mb-3">{activeMenu}</div>
                        <div className="row g-2">
                            {formatOptions[activeMenu]?.map((option, idx) => (
                                <div key={idx} className="col-6">
                                    <button
                                        onClick={() => addElement(option.identity)}
                                        className="btn btn-dark text-start w-100 d-flex align-items-center gap-2 py-2">
                                        <option.icon size={16} />
                                        <span className="small">{option.label}</span>
                                        {option.shortcut && (
                                            <small className="text-secondary ms-auto">{option.shortcut}</small>
                                        )}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="d-flex flex-column gap-1 bg-white shadow rounded p-2">
                    {menuItems.map((item, index) => {
                        const IconComponent = item.icon;
                        return (
                            <button
                                key={index}
                                className={`btn btn-link p-2 ${activeMenu === item.label ? 'text-primary' : 'text-secondary'
                                    }`}
                                onClick={() => setActiveMenu(activeMenu === item.label ? null : item.label)}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title={item.label}
                            >
                                <IconComponent size={20} />
                            </button>
                        );
                    })}
                </div>
            </div>

            <style>
                {`
                    .btn-link:hover {
                        color: var(--bs-primary) !important;
                    }
                    
                    .btn-link {
                        transition: color 0.2s;
                    }

                    .btn-dark:hover {
                        background-color: var(--bs-gray-700);
                    }
                `}
            </style>
        </div>
    );
};

export default SidebarMenu;