import React from "react";

function EventAgenta({ data }) {
    let dataList = data.agendaItems;

    return (
        <>
            {/* <div style={{
            backgroundColor: "transparent",
            padding: "",
            borderRadius: "8px",
            boxShadow: "none",
            maxWidth: "600px",
            margin: "0", // Centering the container
        }}>
            <h3 style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#fff",
                marginBottom: "10px"
            }}>
                {data.headding}
            </h3>
            <p style={{
                fontSize: "16px",
                fontWeight: "normal",
                color: "white",
                margin: "0",
            }}>
                <strong style={{ color: "#007BFF" }}>{dataList.time}</strong> -
                <div dangerouslySetInnerHTML={{ __html: dataList.description }} />
            </p>
            </div> */}
            <div className="event-description-sec mt-3">
                <div className="event-description-title event-xyt">
                    {data.headding}
                </div>
                <div className="event-description-text">
                    <strong style={{ color: "#007BFF" }}>{dataList.time}</strong> -
                    <div dangerouslySetInnerHTML={{ __html: dataList.description }} />
                </div>
            </div>
        </>
    );
}

export default EventAgenta;
