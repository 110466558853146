import React, { useEffect, useState } from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import './DiscoverEventsNav.css'

function DiscoverEventsNav() {
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const checkIfLoggedIn = () => {
        const token = localStorage.getItem('user');
        return token !== null;
    };

    return (
        <div className="sticky-top">
            <nav className="navbar navbar-expand-lg sticky-top" style={{ backdropFilter: 'blur(16px)', color: 'hsla(0, 0%, 100%, .5)' }}>
                {/* <div style={{ background: 'linear-gradient(rgba(93, 225, 255, 0.2) 0%, rgba(92, 255, 144, 0.1) 50%, rgba(194, 255, 95, 0) 100%)', position: 'fixed', pointerEvents: 'none', opacity: '1', top: '0', left: '0', width: '100%', height: '180px', zIndex: '1' }}></div> */}
                <div className="container-fluid">
                    <a className="navbar-brand light-fcolor" href="#">
                        <img src={require('../../assets/images/logo/Popuplogo.png')} alt="logo" style={{ width: '40px', height: '40px' }} />
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto align-items-center">
                            <li className="nav-item nav-link light-fcolor">
                                <span>
                                    {time.toLocaleTimeString("en-US", {
                                        timeZone: "Asia/Kolkata",
                                        hour12: true,
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })} GMT+5:30
                                </span>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link light-fcolor" href="/events-nearby" onMouseEnter={(e) => (e.target.style.color = 'rgba(255, 255, 255, 0.8)')} onMouseLeave={(e) => (e.target.style.color = 'hsla(0, 0%, 100%, 0.5)')}>
                                    Explore Events <FiArrowUpRight />
                                </a>
                            </li>

                            {
                                checkIfLoggedIn() ?
                                    <li className="nav-item">
                                        <div className={`sec-center d-flex align-items-center`}>
                                            <label className="for-dropdown profile-btn-view" htmlFor="dropdown">
                                                <div className="user-image"> <img src={require('../../landingassets/Images/user-profile.jpg')} className="img-responsive modal_profile_img2" /></div>
                                                <span className="user-name m-0">{(JSON.parse(localStorage.getItem('user')).fname)} {(JSON.parse(localStorage.getItem('user')).lname)}</span>
                                            </label>
                                          
                                        </div>

                                    </li>
                                    :

                                    <li className="nav-item light-fcolor">
                                        <a
                                            className="nav-link light-fcolor"
                                            href="/login"
                                            style={{
                                                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                                borderRadius: '100px',
                                                padding: '5px 10px',
                                                color: 'rgba(255, 255, 255, 0.64)',
                                                transition: 'background-color 0.3s ease',
                                            }}
                                            onMouseEnter={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)')}
                                            onMouseLeave={(e) => (e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.08)')}
                                        >
                                            Sign In
                                        </a>
                                    </li>
                            }

                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default DiscoverEventsNav