import React, { useState } from "react";
import { DateTime } from "luxon";
import { FiMoreHorizontal, FiMoreVertical, FiPlusCircle } from "react-icons/fi";

import TextEditor from "../texteditor/TextEditor";
import HorizontalButtonList from "../buttons/ButtonsList";

const initialColumns = [
  {
    title: "Reduce, Reuse, Recycle",
    content:
      "Embrace the 3Rs: Reduce consumption, reuse items creatively, and recycle diligently to minimize waste and its environmental impact.",
  },
  {
    title: "Energy Efficiency",
    content:
      "Switch to energy-efficient appliances, opt for public transport, and choose walking or cycling for short journeys. Small changes make a big difference!",
  },
  {
    title: "Sustainable Food Choices",
    content:
      "Reduce meat consumption, buy local and seasonal produce, and minimize food waste to lessen your carbon footprint significantly.",
  },
  {
    title: "Conscious Consumption",
    content:
      "Support sustainable businesses, choose products with minimal packaging, and reduce your overall consumption to live more lightly on the planet.",
  },
];

const App = ({ content,blockId }) => {
  const [columns, setColumns] = useState(content.columns || []);

  const addColumn = () => {
    setColumns([...columns, { title: "New Column", content: "New content" }]);
  };

  const removeColumn = (index) => {
    setColumns(columns.filter((_, i) => i !== index));
  };

  const currenttime = () => {
    const utcTime = "2025-01-08T10:41:45.000Z";

    // Convert to Luxon DateTime object in UTC
    const californiaTime = DateTime.fromISO(utcTime, { zone: "utc" }).setZone(
      "America/Los_Angeles"
    );

    return californiaTime.toFormat("dd LLLL yyyy hh:mm a");
  };

  const currenttime2 = () => {
    const date = new Date("2025-01-08T10:41:45.000Z");

    // Get the month, date, and year
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of year

    // Get the hours, minutes, and determine AM/PM
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Format the final string
    return `${month}/${day}/${year} at ${hours}:${minutes}${ampm}`;
  };

  return (
    <div
      className="container position-relative"
      onMouseEnter={(e) => {
        e.currentTarget
          .querySelector(".container-hover-border")
          .classList.remove("d-none");
        e.currentTarget
          .querySelector(".container-three-dot-menu")
          .classList.remove("d-none");
        e.currentTarget
          .querySelector(".card-plus-menu-icon")
          .classList.remove("d-none");
      }}
      onMouseLeave={(e) => {
        e.currentTarget
          .querySelector(".container-hover-border")
          .classList.add("d-none");
        e.currentTarget
          .querySelector(".container-three-dot-menu")
          .classList.add("d-none");
        e.currentTarget
          .querySelector(".card-plus-menu-icon")
          .classList.add("d-none");
      }}
    >
      {/* Hover Border for Container */}
      <div
        className="container-hover-border d-none position-absolute w-100 h-100 border border-2"
        style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
      ></div>

      {/* 3-Dot Menu for Container */}
      <div
        className="container-three-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
        style={{ zIndex: 10, top: "0px", left: "-36px" }}
      >
        <button
          className="btn btn-link text-secondary"
          onClick={() => console.log(`3-dot menu clicked for the container`)}
        >
          <FiMoreVertical size={20} />
        </button>
      </div>
      <div
        className="card-plus-menu-icon d-none position-absolute bg-white shadow-sm rounded"
        style={{
          zIndex: 15,
          right: "0px",
          top: "0px",
          bottom: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button className="btn btn-link text-secondary" onClick={addColumn}>
          <FiPlusCircle size={20} />
        </button>
      </div>

      {/* Row and Columns */}
      <div className="row" style={{ marginRight: 0, marginLeft: 0 }}>
        {columns.map((column, index) => (
          <div
            className="col-md-3 position-relative"
            key={index}
            style={{ paddingRight: 0, paddingLeft: 0 }}
            onMouseEnter={(e) => {
              e.currentTarget
                .querySelector(".card-hover-border")
                .classList.remove("d-none");
              e.currentTarget
                .querySelector(".card-three-dot-menu")
                .classList.remove("d-none");
            }}
            onMouseLeave={(e) => {
              e.currentTarget
                .querySelector(".card-hover-border")
                .classList.add("d-none");
              e.currentTarget
                .querySelector(".card-three-dot-menu")
                .classList.add("d-none");
            }}
          >
            {/* Hover Border for Card */}
            <div
              className="card-hover-border d-none position-absolute w-100 h-100 border border-2"
              style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 10 }}
            ></div>

            {/* 3-Dot Menu for Card */}
            <div
              className="card-three-dot-menu d-none position-absolute bg-white shadow-sm rounded"
              style={{
                zIndex: 15,
                left: "140px",
                right: "140px",
                top: "-10px",
              }}
            >
              <button
                className="btn btn-link text-secondary"
                onClick={() =>
                  console.log(`3-dot menu clicked for card ${index}`)
                }
              >
                <FiMoreHorizontal size={20} />
              </button>
            </div>

            {/* Card Content */}
            <div className="card mb-4 h-100 d-flex flex-column">
              <div className="card-body d-flex flex-column">
                {/* <h5 className="card-title">{column.title}</h5>
                <p className="card-text">{column.content}</p> */}
                {
                  column.content.map((itm, itmIndex) => (
                    <>

                      {itm.type === "TEXT" ?
                        <TextEditor blockId={blockId} content={itm} key={itm.id} /> :
                        itm.type === columns ? <HorizontalButtonList
                          blockId={blockId}
                          content={itm}
                          key={itm.id}
                        /> : null}

                    </>


                  ))
                }
                <div className="d-flex align-items-center justify-content-center">
                  <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: 'turquoise', borderRadius: '50%', height: '30px', width: '30px', cursor: 'pointer' }}><FiPlusCircle className="text-white" size={20} /></div>
                </div>
                <div className="mt-auto">
                  <button
                    className="btn btn-danger"
                    onClick={() => removeColumn(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
