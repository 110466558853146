import React, { useState, useRef, useEffect } from "react";
import {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignCenter,
    AlignRight,
    List,
    X,
} from "lucide-react";

import "./demo.css"
const WysiwygEditor = ({
    initialContent = "",
    onContentChange = () => { },
    className = "",
    editorId,
    openEditor,
    setOpenEditor,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const editorRef = useRef(null);
    const modalRef = useRef(null);

    // Handle formatting commands
    const handleFormat = (command) => {
        document.execCommand(command, false, null);
        onContentChange(editorRef.current.innerHTML);
    };

    // Handle opening and closing modal
    const handleClick = () => {
        setOpenEditor(editorId);  // Set this editor as the open editor
        setModalOpen(true);  // Open the modal
    };

    // Close modal when clicked outside
    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && editorRef.current && !editorRef.current.contains(event.target)) {
            setModalOpen(false);  // Close modal if clicked outside
            setOpenEditor(null);  // Reset the open editor
        }
    };

    // Add and remove event listener
    useEffect(() => {
        if (modalOpen) {
            document.addEventListener("click", handleOutsideClick);
        } else {
            document.removeEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [modalOpen]);

    return (
        <div className={`relative ${className}`}>
            {/* Editable Text */}
            <div
                ref={editorRef}
                contentEditable
                className="focus:outline-none w-full"
                dangerouslySetInnerHTML={{ __html: initialContent }}
                onInput={(e) => onContentChange(e.currentTarget.innerHTML)}
                onClick={handleClick}  // Open modal on click
            />

            {/* Modal Editor Menu */}
            {modalOpen && openEditor === editorId && (  // Only show the modal if this editor is the one open
                <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
                    <div
                        ref={modalRef}
                        className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full relative"
                    >
                        {/* Close Button */}
                        {/* <button
                            onClick={() => {
                                setModalOpen(false);
                                setOpenEditor(null);
                            }}
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                            title="Close"
                        >
                            <X size={20} />
                        </button> */}

                        {/* Editor Toolbar */}
                        <div className="flex gap-2 justify-center">
                            <button
                                onClick={() => handleFormat("bold")}
                                className="p-2 hover:bg-gray-100 rounded transition"
                                title="Bold"
                            >
                                <Bold size={20} />
                            </button>
                            <button
                                onClick={() => handleFormat("italic")}
                                className="p-2 hover:bg-gray-100 rounded transition"
                                title="Italic"
                            >
                                <Italic size={20} />
                            </button>
                            <button
                                onClick={() => handleFormat("underline")}
                                className="p-2 hover:bg-gray-100 rounded transition"
                                title="Underline"
                            >
                                <Underline size={20} />
                            </button>
                            <div className="w-px h-6 bg-gray-300" />
                            <button
                                onClick={() => handleFormat("justifyLeft")}
                                className="p-2 hover:bg-gray-100 rounded transition"
                                title="Align Left"
                            >
                                <AlignLeft size={20} />
                            </button>
                            <button
                                onClick={() => handleFormat("justifyCenter")}
                                className="p-2 hover:bg-gray-100 rounded transition"
                                title="Align Center"
                            >
                                <AlignCenter size={20} />
                            </button>
                            <button
                                onClick={() => handleFormat("justifyRight")}
                                className="p-2 hover:bg-gray-100 rounded transition"
                                title="Align Right"
                            >
                                <AlignRight size={20} />
                            </button>
                            <div className="w-px h-6 bg-gray-300" />
                            <button
                                onClick={() => handleFormat("insertUnorderedList")}
                                className="p-2 hover:bg-gray-100 rounded transition"
                                title="Bullet List"
                            >
                                <List size={20} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export const HeadingAndTextCards = ({ mainHeading, textCards = [], onUpdate, openEditor, setOpenEditor }) => {
    const handleHeadingChange = (newContent) => {
        onUpdate({
            mainHeading: newContent,
            textCards: textCards
        });
    };

    const handleCardUpdate = (index, field, newContent) => {
        const updatedCards = textCards.map((card, i) =>
            i === index ? { ...card, [field]: newContent } : card
        );
        onUpdate({
            mainHeading,
            textCards: updatedCards
        });
    };

    return (
        <div className="container my-5">
            <div className="text-center mb-4">
                <WysiwygEditor
                    initialContent={mainHeading}
                    onContentChange={handleHeadingChange}
                    className="inline-block min-w-64"
                    editorId="main-heading"
                    openEditor={openEditor}
                    setOpenEditor={setOpenEditor}
                />
            </div>
            <div className="row">
                {textCards.map((card, index) => (
                    <div className="col-md-6 mb-4" key={index}>
                        <div className="card h-100">
                            <div className="card-body">
                                <WysiwygEditor
                                    initialContent={card.title}
                                    onContentChange={(newContent) => handleCardUpdate(index, 'title', newContent)}
                                    className="card-title"
                                    editorId={`card-title-${index}`}
                                    openEditor={openEditor}
                                    setOpenEditor={setOpenEditor}
                                />
                                <WysiwygEditor
                                    initialContent={card.description}
                                    onContentChange={(newContent) => handleCardUpdate(index, 'description', newContent)}
                                    className="card-text"
                                    editorId={`card-description-${index}`}
                                    openEditor={openEditor}
                                    setOpenEditor={setOpenEditor}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default function App() {
    const [sections, setSections] = useState([
        {
            "title": "Specialty Coffees",
            "description": {
                "type": "HEADING_AND_TEXT_CARDS",
                "components": {
                    "mainHeading": "<h2>Explore Our Signature Blends</h2>",
                    "textCards": [
                        {
                            "title": "<h5>Espresso Con Panna</h5>",
                            "description": "<p>A classic Italian favorite. Rich espresso topped with a dollop of fresh cream.</p>"
                        },
                        {
                            "title": "<h5>Iced Coffee</h5>",
                            "description": "<p>Perfect for hot summer days. Cold brew coffee with a hint of sweetness. Cold brew coffee with a hint of sweetness. Perfect for hot summer days. Cold brew coffee with a hint of sweetness. Cold brew coffee with a hint of sweetness.</p>"
                        }
                    ]
                }
            }
        }
    ]);

    const [openEditor, setOpenEditor] = useState(null);

    const handleSectionUpdate = (index, components) => {
        setSections(prevSections => {
            const newSections = [...prevSections];
            newSections[index] = {
                ...newSections[index],
                description: {
                    ...newSections[index].description,
                    components
                }
            };
            return newSections;
        });
    };

    const renderSection = (description, index) => {
        switch (description.type) {
            case "HEADING_AND_TEXT_CARDS":
                return (
                    <HeadingAndTextCards
                        mainHeading={description.components.mainHeading}
                        textCards={description.components.textCards}
                        onUpdate={(components) => handleSectionUpdate(index, components)}
                        openEditor={openEditor}
                        setOpenEditor={setOpenEditor}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {sections.map((section, index) => (
                <div key={index} className={`section-${section.description.type}`}>
                    {renderSection(section.description, index)}
                </div>
            ))}
        </div>
    );
}
